<template>
  <div
    style="
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      margin-top: 10px;
    "
  >
    费用清单
  </div>
  <a-spin size="large" :spinning="spinning">
    <div class="container">
      <div style="padding: 10px; box-sizing: border-box; background: #fff">
        <!-- <a-table
          :columns="columns"
          :data-source="dataSource"
          :row-selection="rowSelection"
          row-key="id"
          :pagination="false"
          size="middle"
          style="font-size: 13px"
        >
        </a-table> -->
        <ul v-for="(item, index) in dataSource" :key="index" class="ulBox">
          <li>
            <p>{{ item.paymentType }}</p>
            <p>{{ item.createTime }}</p>
          </li>
          <li style="font-weight: bold">{{ item.amount }}</li>
        </ul>
      </div>
      <!-- 分页 -->
      <div class="pageBox">
        <span style="margin-right: 10px">共{{ total }}条</span>
        <a-pagination
          @change="pagination"
          v-model:current="currentPage"
          v-model:pageSize="defaultPageSize"
          simple
          :total="total"
        />
      </div>
    </div>
  </a-spin>
</template>
<script>
import { showToast } from "vant";
import { showNotify } from "vant";
import { queryDaliy } from "@/api/api";
export default {
  data() {
    return {
      spinning: false,
      currentPage: "1", //当前页
      total: "", //总条数
      defaultPageSize: "10", //每页几条
      //   columns: [
      //     {
      //       title: "公司全称",
      //       dataIndex: "label",
      //       align: "center",
      //     },
      //     {
      //       title: "行业大类",
      //       dataIndex: "industry",
      //       align: "center",
      //     },
      //     {
      //       title: "在保人数",
      //       dataIndex: "appNum",
      //       align: "center",
      //     },
      //   ],
      dataSource: [],
      // 复选框
      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(selectedRows);
        },
        getCheckboxProps: (record) => ({
          // 禁用复选框
          // disabled: record.name === "Disabled User",
        }),
      },
    };
  },
  created() {
    this.pagination();
  },
  methods: {
    //分页数据
    pagination() {
      this.spinning = true;
      queryDaliy({
        limit: this.defaultPageSize,
        currentPage: this.currentPage,
      }).then((res) => {
        if (res.code == "0") {
          this.total = res.list.total;
          this.dataSource = res.list.records;
        } else if (res.code == "1") {
          setTimeout(() => {
            this.$router.push({
              name: "login",
            });
          }, 1000);
        } else if (res.code == "2") {
          setTimeout(() => {
            this.$router.push({
              name: "login",
            });
          }, 1000);
        }
        this.spinning = false;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
}
/deep/ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 0;
}
.ulBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  margin: 8px 0;
  li {
    p:first-child {
      font-weight: bold;
      height: 15px;
    }
    p:last-child {
      height: 15px;
      font-size: 12px;
    }
  }
}
.pageBox {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
}
//表头
/deep/.ant-table-thead > tr > th {
  background: rgb(221 235 255) !important;
  font-weight: bold !important;
}
</style>