<template>
  <div
    style="
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      margin-top: 10px;
    "
  >
    减保
  </div>
  <a-spin size="large" :spinning="spinning">
    <a-form
      layout="inline"
      :model="form"
      style="margin-bottom: 15px; padding: 10px"
      :rules="rules"
      @finish="onFinish"
    >
      <div>
        <a-row :gutter="[8, 24]" v-if="showModule">
          <div style="padding: 10px">
            <img
              src="@/assets/减保1.png"
              style="width: 100%; height: auto; display: block"
            />
          </div>
          <div class="container">
            <a-col span="24">
              <a-form-item>
                <p style="height: 15px" slot="label">
                  <span style="color: red; margin-right: 3px">*</span>投保产品
                </p>
                <a-select
                  ref="select"
                  v-model:value="form.productCode"
                  :disabled="disabled"
                  allowClear
                  style="width: 100%"
                  :options="productCode"
                  @change="productCodeChange"
                  placeholder="请选择投保产品"
                ></a-select>
              </a-form-item>
            </a-col>
            <a-col span="24">
              <a-form-item>
                <p style="height: 15px" slot="label">
                  <span style="color: red; margin-right: 3px">*</span>投保方案
                </p>
                <a-select
                  ref="select"
                  allowClear
                  :disabled="disabled"
                  v-model:value="form.planCode"
                  style="width: 100%"
                  @click="planCodeClick"
                  @change="planCodeChange"
                  :options="planCode"
                  placeholder="请选择投保方案"
                ></a-select>
              </a-form-item>
            </a-col>
            <a-col span="24">
              <a-form-item>
                <p style="height: 15px" slot="label">
                  <span style="color: red; margin-right: 3px">*</span>生效日期
                </p>
                <a-date-picker
                  v-model:value="form.effectiveDate"
                  :showToday="false"
                  :disabled="timeDisabled"
                  inputReadOnly="false"
                  :locale="locale"
                  placeholder="请设置减保生效日期"
                  format="YYYY-MM-DD"
                  valueFormat="YYYY-MM-DD"
                  :disabledDate="disabledDate"
                  style="width: 100%"
                  @click="timeClick"
                />
              </a-form-item>
            </a-col>
            <!-- 底部保存返回 -->
            <div class="bottomBox">
              <a-button type="primary" block @click="lastBtn">
                <span>下一步</span>
              </a-button>
            </div>
          </div>
        </a-row>
        <!-- 下一页 -->
        <a-row :gutter="[8, 24]" v-else>
          <div style="padding: 10px">
            <img
              src="@/assets/减保2.png"
              style="width: 100%; height: auto; display: block"
            />
          </div>
          <div class="container">
            <a-col span="24">
              <a-form-item>
                <p style="height: 15px" slot="label">
                  <span style="color: red; margin-right: 3px">*</span>员工姓名
                </p>
                <a-input
                  v-model:value="form.customerName"
                  style="width: 100%"
                  placeholder="请输入员工姓名"
                />
              </a-form-item>
            </a-col>
            <a-col span="24">
              <a-form-item>
                <p style="height: 15px" slot="label">
                  <span style="color: red; margin-right: 3px"></span>身份证号码
                </p>
                <a-input
                  v-model:value="form.idNo"
                  style="width: 100%"
                  placeholder="请输入身份证号码"
                >
                  <template #suffix>
                    <a-tooltip>
                      <van-uploader :after-read="afterRead" accept="image/*">
                        <van-icon name="scan" size="20" />
                      </van-uploader>
                    </a-tooltip>
                  </template>
                </a-input>
              </a-form-item>
            </a-col>
            <a-col span="24">
              <a-form-item>
                <p style="height: 15px" slot="label">
                  <span style="color: red; margin-right: 3px"></span>用工企业
                </p>
                <a-select
                  ref="select"
                  allowClear
                  v-model:value="form.companyCode"
                  style="width: 100%"
                  show-search
                  :filter-option="filtercompanyCode"
                  :options="companyCode"
                  @click="companyCodeClick"
                  placeholder="请选择用工企业"
                ></a-select>
              </a-form-item>
            </a-col>
            <div class="bottomBox">
              <van-button
                type="primary"
                style="height: 35px !important"
                @click="addUser"
              >
                查询
              </van-button>
            </div>
            <!-- 表格 -->
            <div style="box-sizing: border-box; width: 100%">
              <a-button danger style="margin: 5px 0" @click="deleteBtn">
                <span>删除</span>
              </a-button>
              <a-table
                :columns="columns"
                :data-source="dataSource"
                :row-selection="rowSelection1"
                row-key="deIdNo"
                :pagination="false"
                size="middle"
                style="font-size: 13px"
              >
              </a-table>
            </div>
            <div class="bottomBox">
              <a-button type="primary" block @click="lastBtn">
                <span>上一步</span>
              </a-button>
              <a-button type="primary" block html-type="submit"
                >减保确认</a-button
              >
            </div>
          </div>
        </a-row>
      </div>
      <!-- 查询表格弹窗 -->
      <van-overlay :show="tableShow">
        <div class="wrapper">
          <div class="block">
            <div style="flex: 1; height: 400px; overflow: auto">
              <a-table
                :columns="columns1"
                :data-source="dataSource1"
                :row-selection="rowSelection11"
                row-key="deIdNo"
                :pagination="false"
                size="middle"
                style="font-size: 13px"
              >
              </a-table>
            </div>
            <div>
              <van-button
                type="info"
                round
                block
                color="#3e77db"
                @click="suerTableBtn"
                >确认</van-button
              >
            </div>
          </div>
        </div>
      </van-overlay>
      <!-- 查看详情弹窗 -->
      <van-overlay :show="detailsShow">
        <div class="wrapper">
          <div class="block">
            <h2 style="text-align: center; height: 60px; line-height: 60px">
              员工减保信息
            </h2>
            <div style="flex: 1; height: 400px; overflow: auto">
              <ul
                class="ulText"
                v-for="(item, index) in customerSuccessDataList"
                :key="index"
              >
                <li>
                  <p>员工姓名：</p>
                  <p>{{ item.customerName }}</p>
                </li>
                <li>
                  <p>身份证号码：</p>
                  <p>{{ item.idNo }}</p>
                </li>
                <li>
                  <p>投保产品：</p>
                  <p>{{ item.productName }}</p>
                </li>
                <li>
                  <p>投保方案：</p>
                  <p>{{ item.planName }}</p>
                </li>
                <li>
                  <p>用工企业：</p>
                  <p>{{ item.companyName }}</p>
                </li>
                <li>
                  <p>职业类别：</p>
                  <p>{{ item.planName }}</p>
                </li>
                <li style="border-bottom: 1px solid #ccc"></li>
              </ul>
              <div style="width: 100%; text-align: center">
                <p>生效日期：{{ startTime }}</p>
                <!-- <p>保险止期：{{ endTime }}</p> -->
              </div>
            </div>
            <div>
              <van-button
                type="info"
                round
                block
                color="#3e77db"
                @click="suerBtn"
                >确认</van-button
              >
            </div>
          </div>
        </div>
      </van-overlay>
    </a-form>
  </a-spin>
</template>
<script>
import { Modal } from "ant-design-vue";
import moment from "moment";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
dayjs.locale("zh-cn");
import { showToast } from "vant";
import { showNotify } from "vant";
import {
  getProposal,
  getCompanyByNo,
  getProposalCustomer,
  delProposal,
  cardOCR,
} from "@/api/api";
export default {
  data() {
    const filtercompanyCode = (input, option) => {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    return {
      filtercompanyCode,
      dayjs,
      locale,
      disabled: false,
      form: {
        productCode: undefined,
        planCode: undefined,
        effectiveDate: "",
        customerName: "",
        idNo: "",
        companyCode: undefined,
        proposalNo: "",
      },
      // 下拉框数据
      productCode: [],
      planCode: [],
      companyCode: [],
      showModule: true,
      spinning: false,
      effectiveDate: "",
      expireDate: "",
      // 禁用
      timeDisabled: true,
      // 表格
      selection: [],
      selection1: [],
      columns: [
        {
          title: "被保险人",
          dataIndex: "customerName",
          align: "center",
        },
        {
          title: "身份证号码",
          dataIndex: "deIdNo",
          align: "center",
        },
        {
          title: "用工企业",
          dataIndex: "companyName",
          align: "center",
        },
        {
          title: "职业类别",
          dataIndex: "occupationName",
          align: "center",
        },
      ],
      columns1: [
        {
          title: "被保险人",
          dataIndex: "customerName",
          align: "center",
        },
        {
          title: "身份证号码",
          dataIndex: "deIdNo",
          align: "center",
        },
        {
          title: "用工企业",
          dataIndex: "companyName",
          align: "center",
        },
        {
          title: "职业类别",
          dataIndex: "occupationName",
          align: "center",
        },
      ],
      dataSource: [],
      dataSource1: [],
      // 复选框
      rowSelection1: {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selection = selectedRows;
        },
        getCheckboxProps: (record) => ({
          // 禁用复选框
          // disabled: record.name === "Disabled User",
        }),
      },
      rowSelection11: {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selection1 = selectedRows;
        },
        getCheckboxProps: (record) => ({
          // 禁用复选框
          // disabled: record.name === "Disabled User",
        }),
      },
      // 减保完成数据
      tableShow: false,
      detailsShow: false,
      customerSuccessDataList: [],
      startTime: "",
      endTime: "",
    };
  },
  created() {
    getProposal().then((res) => {
      this.productCode = res.data;
    });
  },
  methods: {
    // 图片上传
    afterRead(file) {
      this.spinning = true;
      cardOCR({ content: file.content }).then((res) => {
        if (res.code == "0") {
          this.form.idNo = res.idNo;
          this.form.customerName = res.name;
          showNotify({ type: "success", message: "身份证上传成功" });
          this.spinning = false;
        } else {
          this.spinning = false;
        }
      });
    },
    lastBtn() {
      this.showModule = !this.showModule;
    },
    disabledDate(current) {
      //只能选择日期区间内 dates格式为[“2022-08-01","2022-09-30”]
      //当天之前不包括当天 current && current < moment().subtract(1, "days")
      if (this.effectiveDate == this.expireDate) {
        let startDay = this.effectiveDate;
        let endDay = this.expireDate;
        return (
          (current && current < moment(startDay).subtract("days")) ||
          current > moment(endDay).endOf("day")
        );
      } else {
        let endDay = this.expireDate;
        return (
          (current && current < moment().endOf(1, "day")) ||
          current > moment(endDay).endOf("day")
        );
      }
    },
    //投保产品联动
    productCodeChange() {
      this.productCode.forEach((v, i) => {
        if (v.value == this.form.productCode) {
          this.planCode = v.children;
        } else {
          this.form.planCode = undefined;
          this.timeDisabled = true;
        }
      });
      this.form.effectiveDate = undefined;
    },
    planCodeClick() {
      if (this.form.productCode == undefined) {
        showToast("请先选择投保方案");
        this.planCode = undefined;
        this.timeDisabled = true;
      }
    },
    planCodeChange() {
      this.planCode.forEach((v, i) => {
        if (v.value == this.form.planCode) {
          this.effectiveDate = v.effectiveDate;
          this.expireDate = v.expireDate;
          this.form.effectiveDate = undefined;
          this.timeDisabled = false;
          this.form.proposalNo = v.code;
          getCompanyByNo({
            proposalNo: this.form.proposalNo,
          }).then((res) => {
            this.companyCode = res.data;
          });
        }
      });
    },
    companyCodeClick() {
      if (this.companyCode.length == 0) {
        showToast("请先选择投保产品和投保方案");
      }
    },
    timeClick() {
      if (this.form.productCode == undefined) {
        showToast("请先选择投保方案");
      }
    },
    // 加人
    addUser() {
      if (this.form.productCode == undefined) {
        showToast("请选择投保产品");
      } else if (this.form.planCode == undefined) {
        showToast("请选择投保方案");
      } else if (
        this.form.effectiveDate == "" ||
        this.form.effectiveDate == undefined
      ) {
        showToast("请选择生效日期");
      } else if (this.form.customerName == "") {
        showToast("请选择员工姓名");
      } else {
        this.disabled = true;
        this.tableShow = true;
        this.spinning = true;
        this.selection1 = [];
        getProposalCustomer(this.form).then((res) => {
          if (res.code == "0") {
            this.dataSource1 = res.data;
            this.form.customerName = "";
            this.form.idNo = "";
          }
          this.spinning = false;
        });
      }
    },
    // 删除
    deleteBtn() {
      if (this.selection.length < 1) {
        showToast("请至少选择一条数据");
      } else {
        Modal.confirm({
          title: "确定要删除选中数据吗？",
          content: "",
          okText: "确定",
          okType: "danger",
          cancelText: "取消",
          onOk: () => {
            for (let i = this.dataSource.length; i > 0; i--) {
              for (let j = 0; j < this.selection.length; j++) {
                if (this.dataSource[i - 1] == this.selection[j]) {
                  if (this.dataSource[i - 1].delete != false) {
                    this.dataSource.splice(i - 1, 1);
                  } else {
                    showToast("已存在数据不可以删除");
                  }
                }
              }
            }
            this.selection = [];
          },
          onCancel() {
            console.log("Cancel");
          },
        });
      }
    },
    // 表格弹窗确认
    suerTableBtn() {
      this.tableShow = false;
      this.selection1.forEach((v, i) => {
        if (this.dataSource.length <= 4) {
          this.dataSource.push(v);
        } else {
          showToast("单词减保最多5人");
        }
      });
    },
    suerBtn() {
      this.detailsShow = false;
      this.$router.push({
        name: "home",
      });
    },
    // 确认按钮
    onFinish() {
      if (
        this.form.effectiveDate == "" ||
        this.form.effectiveDate == undefined
      ) {
        showToast("请选择生效日期");
      } else {
        this.spinning = true;
        delProposal({
          effectiveDate: this.form.effectiveDate,
          employeeDataList: this.dataSource,
          proposalNo: this.form.proposalNo,
        }).then((res) => {
          if (res.code == "0") {
            this.customerSuccessDataList = res.data.customerSuccessDataList;
            this.startTime = res.data.startTime;
            this.endTime = res.data.endTime;
            showNotify({ type: "success", message: "减保成功" });
            setTimeout(() => {
              this.spinning = false;
              this.detailsShow = true;
            }, 500);
          } else {
            this.spinning = false;
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 15px;
  box-sizing: border-box;
}
.bottomBox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  button {
    margin: 0 10px;
    height: 35px;
    background: #3e77db;
  }
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.block {
  width: 90%;
  height: 90%;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  overflow: auto;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}
.ulText {
  width: 100%;
  li {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    p:first-child {
      display: block;
      width: 40%;
      text-align: right;
    }
    p:last-child {
      width: 59%;
    }
  }
}
/deep/.ant-col-24 {
  margin-left: 5px;
  height: 80px;
}
/deep/.ant-form-item-label {
  height: 30px;
}
/deep/.ant-picker-panel-container .ant-picker-panels {
  display: inline-flex !important;
  justify-content: center !important;
}
//表头
/deep/.ant-table-thead > tr > th {
  background: rgb(221 235 255) !important;
  font-weight: bold !important;
}
</style>