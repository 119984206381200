<template>
  <div
    style="
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      margin-top: 10px;
    "
  >
    在线报案
  </div>
  <a-spin size="large" :spinning="spinning">
    <!-- 理赔报案提示 -->
    <div>
      <a-modal
        v-model:visible="visible"
        :closable="false"
        :keyboard="false"
        :maskClosable="false"
        width="100%"
      >
        <template #title>
          <div
            style="
              text-align: center;
              font-weight: bold;
              font-size: 16px;
              height: 40px;
              line-height: 40px;
              background: linear-gradient(
                to right,
                rgb(221 235 255),
                rgb(252 243 244)
              );
            "
          >
            报案告知书
          </div>
        </template>
        <template #footer>
          <a-button
            key="submit"
            type="primary"
            :disabled="isTimeOver"
            @click="handleOk"
            >知道了{{ timeOver }}</a-button
          >
        </template>
        <img
          src="https://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D/85cdb1047f0ed1a6d5db5103b5b4168.png"
          style="width: 100%; height: auto; display: block"
        />
      </a-modal>
    </div>
    <a-form
      layout="inline"
      :model="form"
      style="margin-bottom: 15px; padding: 10px"
      :rules="rules"
      @finish="onFinish"
    >
      <div class="container">
        <!-- 第一页 -->
        <a-row :gutter="[8, 24]" v-if="showModule == '0'">
          <a-col span="24">
            <a-form-item>
              <p style="height: 15px" slot="label">
                <span style="color: red; margin-right: 3px">*</span>出险人名称
              </p>
              <a-input
                v-model:value="form.customerName"
                style="width: 100%"
                placeholder="请输入出险人名称"
              />
            </a-form-item>
          </a-col>
          <a-col span="24">
            <a-form-item>
              <p style="height: 15px" slot="label">
                <span style="color: red; margin-right: 3px">*</span>身份证号码
              </p>
              <a-input
                v-model:value="form.idNo"
                style="width: 100%"
                placeholder="请输入身份证号码"
              >
                <template #suffix>
                  <a-tooltip>
                    <van-uploader :after-read="afterRead" accept="image/*">
                      <van-icon name="scan" size="20" />
                    </van-uploader>
                  </a-tooltip>
                </template>
              </a-input>
            </a-form-item>
          </a-col>
          <a-col span="24">
            <a-form-item>
              <p style="height: 15px" slot="label">
                <span style="color: red; margin-right: 3px">*</span>出险人电话
              </p>
              <a-input
                v-model:value="form.customerPhone"
                style="width: 100%"
                placeholder="请输入出险人电话"
              />
            </a-form-item>
          </a-col>
          <a-col span="24">
            <a-form-item>
              <p style="height: 15px" slot="label">
                <span style="color: red; margin-right: 3px">*</span>出险日期
              </p>
              <a-date-picker
                v-model:value="form.accidentTime"
                :showToday="false"
                inputReadOnly="false"
                :locale="locale"
                placeholder="请选择出险日期"
                format="YYYY-MM-DD"
                valueFormat="YYYY-MM-DD"
                style="width: 100%"
                @click="timeClick"
              />
            </a-form-item>
          </a-col>
          <a-col span="24">
            <a-form-item>
              <p style="height: 15px" slot="label">
                <span style="color: red; margin-right: 3px">*</span>报案人电话
              </p>
              <a-input
                v-model:value="form.reportPhone"
                style="width: 100%"
                placeholder="请输入报案人电话"
              />
            </a-form-item>
          </a-col>
          <a-col span="24">
            <a-form-item>
              <p style="height: 15px" slot="label">
                <span style="color: red; margin-right: 3px">*</span>受伤程度
              </p>
              <a-select
                ref="select"
                allowClear
                v-model:value="form.hurt"
                style="width: 100%"
                :options="hurt"
                placeholder="请选择受伤程度"
              ></a-select>
            </a-form-item>
          </a-col>
          <!-- 底部保存返回 -->
          <div class="bottomBox">
            <a-button type="primary" block @click="lastBtn((item = '1'))">
              <span>下一步</span>
            </a-button>
          </div>
        </a-row>
        <!-- 第二页 -->
        <a-row :gutter="[8, 24]" v-if="showModule == '1'">
          <a-col :span="24">
            <a-form-item>
              <p style="height: 15px" slot="label">
                <span style="color: red; margin-right: 3px">*</span>事故时间
              </p>
              <a-radio-group v-model:value="form.accidentMoment">
                <a-radio
                  v-for="item in accidentMoment"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.label }}</a-radio
                >
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col span="24">
            <a-form-item>
              <p style="height: 15px" slot="label">
                <span style="color: red; margin-right: 3px">*</span>出险地点
              </p>
              <a-input
                v-model:value="form.address"
                style="width: 100%"
                placeholder="请输入出险地点"
              />
            </a-form-item>
          </a-col>
          <a-col span="24">
            <a-form-item>
              <p style="height: 15px" slot="label">
                <span style="color: red; margin-right: 3px">*</span>事故经过
              </p>
              <a-input
                v-model:value="form.accidentInfo"
                style="width: 100%"
                placeholder="请输入事故经过"
              />
            </a-form-item>
          </a-col>
          <div style="width: 100%; padding: 10px; box-sizing: border-box">
            <a-form-item>
              <p style="height: 15px" slot="label">
                <span style="color: red; margin-right: 3px"></span
                >员工受伤后照片 上传数量最多5张
              </p>
              <van-uploader
                style="padding: 10px"
                v-model="form.injuredPicture"
                accept="image/*"
                :max-count="5"
                multiple
              >
              </van-uploader>
            </a-form-item>
          </div>
          <div style="width: 100%; padding: 10px; box-sizing: border-box">
            <a-form-item>
              <p style="height: 15px" slot="label">
                <span style="color: red; margin-right: 3px"></span>伤者视频上传
                上传数量最多3条
              </p>
              <van-uploader
                style="padding: 10px"
                v-model="form.injuredVideo"
                :max-count="3"
                accept="video/*"
                multiple
              >
              </van-uploader>
            </a-form-item>
          </div>
          <div class="bottomBox">
            <a-button type="primary" block @click="lastBtn((item = '0'))">
              <span>上一步</span>
            </a-button>
            <a-button type="primary" block html-type="submit">提交</a-button>
          </div>
        </a-row>
      </div>
    </a-form>
  </a-spin>
</template>
<script>
import { showToast } from "vant";
import { showNotify } from "vant";
import moment from "moment";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
dayjs.locale("zh-cn");
import { getSelectorData, claimCaseAdd, cardOCR } from "@/api/api";
export default {
  data() {
    return {
      dayjs,
      locale,
      form: {
        customerName: "",
        idNo: "",
        // customerCode: "",
        customerPhone: "",
        accidentTime: "",
        accidentMoment: "",
        hurt: undefined,
        reportPhone: "",
        address: "",
        accidentInfo: "",
        injuredVideo: [],
        injuredPicture: [],
      },
      hurt: [],
      accidentMoment: [],
      isTimeOver: true,
      visible: true,
      timeOver: 5,
      showModule: "0",
      spinning: false,
    };
  },
  created() {
    getSelectorData().then((res) => {
      this.hurt = res.data.hurt;
      this.accidentMoment = res.data.accidentMoment;
    });
    //点击理赔报案直接展示倒计时5秒
    this.timeOver = 5;
    this.isTimeOver = true;
    let clock = window.setInterval(() => {
      this.timeOver--;
      if (this.timeOver < 1) {
        this.timeOver = "";
        this.isTimeOver = false;
        window.clearInterval(clock); //关闭
      }
    }, 1000);
  },
  methods: {
    handleOk() {
      this.visible = false;
    },
    lastBtn(item) {
      this.showModule = item;
    },
    // 身份证上传
    afterRead(file) {
      this.spinning = true;
      cardOCR({ content: file.content }).then((res) => {
        if (res.code == "0") {
          this.form.idNo = res.idNo;
          this.form.customerName = res.name;
          showNotify({ type: "success", message: "身份证上传成功" });
          this.spinning = false;
        } else {
          this.spinning = false;
        }
      });
    },
    // 上传图片
    afterUpload({ file }) {
      this.spinning = true;
      if (file.response) {
        if (file.response.code == "0") {
          showToast("上传图片成功");
          this.spinning = false;
        } else if (file.response.code == "500") {
          this.form.injuredPicture = [];
          showToast(file.response.msg);
          this.spinning = false;
        }
      }
    },
    // 上传视频
    afterUpload1({ file }) {
      this.spinning = true;
      if (file.response) {
        if (file.response.code == "0") {
          showToast("上传视频成功");
          this.spinning = false;
        } else if (file.response.code == "500") {
          this.form.injuredVideo = [];
          showToast(file.response.msg);
          this.spinning = false;
        }
      }
    },
    onFinish() {
      const phoneCheck = /^1[34578]\d{9}$/;
      if (this.form.customerName == "") {
        showToast("请输入出险人名称");
      } else if (this.form.idNo == "") {
        showToast("请输入身份证号码");
      } else if (phoneCheck.test(this.form.customerPhone) == false) {
        showToast("请输入正确的出险人电话");
      } else if (this.form.accidentTime == "") {
        showToast("请选择出现日期");
      } else if (phoneCheck.test(this.form.reportPhone) == false) {
        showToast("请输入正确的报案人电话");
      } else if (this.form.hurt == undefined) {
        showToast("请选择受伤程度");
      } else if (this.form.accidentMoment == "") {
        showToast("请选择事故时间");
      } else if (this.form.address == "") {
        showToast("请输入出险地点");
      } else if (this.form.accidentInfo == "") {
        showToast("请输入事故经过");
      } else if (
        this.form.injuredPicture == "" &&
        this.form.injuredVideo == ""
      ) {
        showToast("请上传员工受伤后照片或视频");
      } else {
        this.spinning = true;
        claimCaseAdd(this.form).then((res) => {
          if (res.code == "0") {
            showNotify({ type: "success", message: "提交成功" });
            setTimeout(() => {
              this.spinning = false;
              this.$router.push({
                name: "home",
              });
            }, 1000);
          } else if (res.code == "1") {
            setTimeout(() => {
              this.$router.push({
                name: "login",
              });
            }, 1000);
          } else if (res.code == "2") {
            setTimeout(() => {
              this.$router.push({
                name: "login",
              });
            }, 1000);
          }
          setTimeout(() => {
            this.spinning = false;
          }, 5000);
        });
      }
    },
  },
};
</script>
  <style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 15px;
  box-sizing: border-box;
}
.bottomBox {
  width: 100%;
  display: flex;
  justify-content: center;
  button {
    margin: 0 10px;
    height: 35px;
    background: #3e77db;
  }
}
/deep/.ant-col-24 {
  margin-left: 5px;
  height: 60px;
}
/deep/.ant-form-item-label {
  height: 30px;
}
</style>