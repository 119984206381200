<template>
  <div>
    <img :src="img" style="width: 100%; height: auto" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      img: "",
    };
  },
  mounted() {
    this.img = this.$route.query.jumpUrl;
  },
};
</script>