<template>
  <div
    style="
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      margin-top: 10px;
    "
  >
    理赔案件进度查看
  </div>
  <a-spin size="large" :spinning="spinning">
    <div style="padding: 10px">
      <div class="container">
        <a-card size="small" :bordered="false" style="margin-bottom: 15px">
          <template #title>
            <van-icon
              name="orders-o"
              size="18"
              style="color: #3e77db; margin-right: 5px"
            />
            <span style="font-weight: bold">报案信息</span>
          </template>
          <div class="textBox">
            <a-row>
              <a-col span="9">出险人姓名：</a-col>
              <a-col span="15">{{ tcustomerInfo.customerName }}</a-col>
            </a-row>
            <van-divider :hairline="false" style="margin: 0" />
            <a-row>
              <a-col span="9">出险人身份证号：</a-col>
              <a-col span="15">{{ tcustomerInfo.customerIdNo }}</a-col>
            </a-row>
            <van-divider :hairline="false" style="margin: 0" />
            <a-row>
              <a-col span="9">出险人手机号：</a-col>
              <a-col span="15">{{ tcustomerInfo.customerPhone }}</a-col>
            </a-row>
            <van-divider :hairline="false" style="margin: 0" />
            <a-row>
              <a-col span="9">用工企业名称：</a-col>
              <a-col span="15">{{ tcustomerInfo.insuranceCompany }}</a-col>
            </a-row>
            <van-divider :hairline="false" style="margin: 0" />
            <a-row>
              <a-col span="9">投保工种：</a-col>
              <a-col span="15">{{ tcustomerInfo.occupation }}</a-col>
            </a-row>
            <van-divider :hairline="false" style="margin: 0" />
            <a-row>
              <a-col span="9">投保方案：</a-col>
              <a-col span="15">{{ tcustomerInfo.productName }}</a-col>
            </a-row>
            <van-divider :hairline="false" style="margin: 0" />
            <a-row>
              <a-col span="9">投保日期：</a-col>
              <a-col span="15">{{ tcustomerInfo.accidentTime }}</a-col>
            </a-row>
            <van-divider :hairline="false" style="margin: 0" />
            <a-row>
              <a-col span="9">报案人手机号：</a-col>
              <a-col span="15">{{ tcustomerInfo.reportPhone }}</a-col>
            </a-row>
            <van-divider :hairline="false" style="margin: 0" />
            <a-row>
              <a-col span="9">报案时间：</a-col>
              <a-col span="15">{{ tcustomerInfo.accidentTime }}</a-col>
            </a-row>
            <van-divider :hairline="false" style="margin: 0" />
            <a-row>
              <a-col span="9">出险地点：</a-col>
              <a-col span="15">{{ tcustomerInfo.address }}</a-col>
            </a-row>
            <van-divider :hairline="false" style="margin: 0" />
            <a-row>
              <a-col span="9">事故经过：</a-col>
              <a-col span="15">{{ tcustomerInfo.accidentInfo }}</a-col>
            </a-row>
          </div>
        </a-card>
        <a-card size="small" :bordered="false" style="margin-bottom: 15px">
          <template #title>
            <van-icon
              name="bulb-o"
              size="18"
              style="color: #3e77db; margin-right: 5px"
            />
            <span style="font-weight: bold">案件资料</span>
          </template>
          <div v-if="this.tcustomerInfo.injuredPicture != ''">
            <ul class="ulBox">
              <li><van-icon name="photo-o" size="20" color="#3e77db" /></li>
              <li>图片</li>
            </ul>
            <van-image
              v-for="(item, index) in tcustomerInfo.injuredPicture"
              :key="index"
              :src="item.value"
              style="margin: 3px; width: 20%; height: 110px"
              @click="imageBtn(index)"
            />
          </div>
          <div
            v-if="this.tcustomerInfo.injuredVideo != ''"
            style="margin-top: 20px"
          >
            <ul class="ulBox">
              <li><van-icon name="video-o" size="20" color="#3e77db" /></li>
              <li>视频</li>
            </ul>
            <div
              v-for="(item, index) in tcustomerInfo.injuredVideo"
              :key="index"
            >
              <van-image
                :key="index"
                src="http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20230518155254.jpg"
                style="margin: 3px; width: 20%; height: 110px; float: left"
                @click="videoBtn(item)"
              />
            </div>
          </div>
          <div style="width: 100%; text-align: center; margin-top: 15px">
            <a-button
              type="primary"
              style="background: #3e77db"
              @click="backBtn"
              >返回</a-button
            >
          </div>
        </a-card>
        <!-- <a-card size="small" :bordered="false" style="margin-bottom: 15px">
          <template #title>
            <van-icon
              name="filter-o"
              size="18"
              style="color: #3e77db; margin-right: 5px"
            />
            <span style="font-weight: bold">案件进展</span>
          </template>
          <div
            v-for="(item, index) in this.tclaimTraceList"
            :key="index"
            style="display: flex"
          >
            <p>[{{ item.timeStamp }}]</p>
            <span style="margin: 6px"></span>
            <p>{{ item.traceTitle }}：</p>
            <p>{{ item.traceDesc }}</p>
          </div>
        </a-card> -->
      </div>
    </div>
  </a-spin>
</template>
<script>
import { showToast } from "vant";
import { showNotify } from "vant";
import { showImagePreview } from "vant";
import { claimCaseAccidentProgress } from "@/api/api";
export default {
  data() {
    return {
      spinning: false,
      tclaimTraceList: [],
      tcustomerInfo: {},
    };
  },
  mounted() {
    this.spinning = true;
    claimCaseAccidentProgress({ caseNo: this.$route.query.caseNo }).then(
      (res) => {
        this.tclaimTraceList = res.data.tclaimTraceList;
        this.tcustomerInfo = res.data.tcustomerInfo;
        this.spinning = false;
      }
    );
  },
  methods: {
    imageBtn(index) {
      const imgData = [];
      this.tcustomerInfo.injuredPicture.forEach((v, i) => {
        imgData.push(v.value);
      });
      showImagePreview({
        images: imgData,
        startPosition: index,
        closeable: true,
      });
    },
    videoBtn(item) {
      window.open(item.value);
    },
    backBtn() {
      //返回上一页
      this.$router.go(-1);
    },
    videoUrl(item) {
      window.open(item.value, "_blank");
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
}
.textBox {
  .ant-col-9 {
    text-align: left;
    margin: 15px 0;
  }
  .ant-col-15 {
    text-align: left;
    margin: 15px 0;
  }
}
.ulBox {
  width: 100%;
  height: 20px;
  line-height: 17px;
  li {
    float: left;
    margin-right: 5px;
  }
}
</style>