import { createRouter, createWebHistory } from 'vue-router'
import home from '../views/homePage/home.vue'
import product from '../views/productPage/product.vue'
import mine from '../views/minePage/mine.vue'
import login from '../views/login.vue'
import addCompany from '../views/module/addCompany.vue'
import queryCompany from '../views/module/queryCompany.vue'
import addInsure from '../views/module/addInsure.vue'
import deleteInsure from '../views/module/deleteInsure.vue'
import insuranceRecords from '../views/module/insuranceRecords.vue'
import insuranceRecordDetails from '../views/module/insuranceRecordDetails.vue'
import toPay from '../views/module/toPay.vue'
import consumptionRecords from '../views/module/consumptionRecords.vue'
import personnelQuery from '../views/module/personnelQuery.vue'
import personnelExchange from '../views/module/personnelExchange.vue'
import onlineReporting from '../views/module/onlineReporting.vue'
import claimInquiry from '../views/module/claimInquiry.vue'
import claimInquiryDetails from '../views/module/claimInquiryDetails.vue'
import about from '../views/module/about.vue'
import imgUrl from '../views/module/imgUrl.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: home,
    meta: {
      keepalive: true
    },
  },
  {
    path: '/home/addCompany',
    name: 'home/addCompany',
    component: addCompany,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/home/queryCompany',
    name: 'home/queryCompany',
    component: queryCompany,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/home/addInsure',
    name: 'home/addInsure',
    component: addInsure,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/home/deleteInsure',
    name: 'home/deleteInsure',
    component: deleteInsure,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/home/insuranceRecords',
    name: 'home/insuranceRecords',
    component: insuranceRecords,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/home/toPay',
    name: 'home/toPay',
    component: toPay,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/home/consumptionRecords',
    name: 'home/consumptionRecords',
    component: consumptionRecords,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/home/personnelQuery',
    name: 'home/personnelQuery',
    component: personnelQuery,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/home/onlineReporting',
    name: 'home/onlineReporting',
    component: onlineReporting,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/home/claimInquiry',
    name: 'home/claimInquiry',
    component: claimInquiry,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/home/about',
    name: 'home/about',
    component: about,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/home/imgUrl',
    name: 'home/imgUrl',
    component: imgUrl,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/home/claimInquiry/claimInquiryDetails',
    name: 'home/claimInquiry/claimInquiryDetails',
    component: claimInquiryDetails,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/home/personnelQuery/personnelExchange',
    name: 'home/personnelQuery/personnelExchange',
    component: personnelExchange,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/home/insuranceRecords/insuranceRecordDetails',
    name: 'home/insuranceRecords/insuranceRecordDetails',
    component: insuranceRecordDetails,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/product',
    name: 'product',
    component: product,
    meta: {
      keepalive: true
    },
  },
  {
    path: '/mine',
    name: 'mine',
    component: mine,
    meta: {
      keepalive: true
    },
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      keepalive: false
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  // 刷新页面
  if (from.name == 'login') {
    location.reload();
  } else {
    next();
  }
});

export default router
