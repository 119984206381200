<template>
  <div
    style="
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      margin-top: 10px;
    "
  >
    理赔案件
  </div>
  <a-spin size="large" :spinning="spinning">
    <div style="padding: 10px">
      <div class="container">
        <a-form
          layout="inline"
          :model="form"
          style="margin-bottom: 15px; background: #fff"
          @finish="onFinish"
        >
          <a-row :gutter="[0, 24]">
            <a-col span="24" style="padding: 10px">
              <a-form-item>
                <p style="height: 15px" slot="label">
                  <span style="color: red; margin-right: 3px"></span>出险人名称
                </p>
                <a-input
                  v-model:value="form.customerName"
                  style="width: 100%"
                  placeholder="请输入出险人名称"
                />
              </a-form-item>
            </a-col>
            <a-col
              span="24"
              style="
                padding-right: 8px;
                margin-top: -15px;
                margin-bottom: -10px;
              "
            >
              <van-field
                v-model="form.time"
                label="出险日期"
                placeholder="请选择出险日期"
                :border="false"
                readonly
                @click="timeShow = true"
                right-icon="close"
              >
                <template v-slot:right-icon>
                  <i
                    class="van-icon van-icon-close"
                    @click.stop="rightIcon"
                  ></i>
                </template>
              </van-field>
              <van-popup v-model:show="timeShow" position="bottom">
                <van-picker-group
                  v-if="timeShow"
                  title="操作日期"
                  :tabs="['开始日期', '结束日期']"
                  @confirm="onConfirm"
                  @cancel="timeShow = false"
                >
                  <van-date-picker
                    v-model="form.startDate"
                    :min-date="minDate"
                    :max-date="maxDate"
                  />
                  <van-date-picker
                    v-model="form.endDate"
                    :min-date="minDate"
                    :max-date="maxDate"
                  />
                </van-picker-group>
              </van-popup>
            </a-col>
            <a-col span="24" style="padding: 0 10px">
              <a-form-item>
                <p style="height: 15px" slot="label">
                  <span style="color: red; margin-right: 3px"></span>用工企业
                </p>
                <a-select
                  ref="select"
                  allowClear
                  show-search
                  :filter-option="filtercompanyCode"
                  v-model:value="form.companyCode"
                  style="width: 100%"
                  :options="company"
                  placeholder="请选择用工企业"
                ></a-select>
              </a-form-item>
            </a-col>
            <a-col span="24" style="padding: 0 10px">
              <a-form-item>
                <p style="height: 15px" slot="label">
                  <span style="color: red; margin-right: 3px"></span>案件状态
                </p>
                <a-select
                  ref="select"
                  allowClear
                  v-model:value="form.accidentStatus"
                  style="width: 100%"
                  :options="accidentStatus"
                  placeholder="请选择案件状态"
                ></a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <div class="bottomBox">
            <a-button @click="lastBtn">
              <span>重置</span>
            </a-button>
            <a-button
              type="primary"
              style="background: #3e77db"
              html-type="submit"
              >查询</a-button
            >
          </div>
        </a-form>
        <div style="box-sizing: border-box; width: 100%">
          <a-table
            :columns="columns"
            :data-source="dataSource"
            row-key="proChgId"
            :pagination="false"
            size="middle"
            style="font-size: 13px"
          >
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'action'">
                <a @click="detailsBtn(record)">进度查看</a>
              </template>
            </template>
          </a-table>
          <!-- 分页 -->
          <div class="pageBox">
            <span style="margin-right: 10px">共{{ total }}条</span>
            <a-pagination
              @change="pagination"
              v-model:current="currentPage"
              v-model:pageSize="defaultPageSize"
              simple
              :total="total"
            />
          </div>
        </div>
      </div>
    </div>
  </a-spin>
</template>
<script>
import { showToast } from "vant";
import { showNotify } from "vant";
import { getClaimCaseList, getClaimCaseData } from "@/api/api";
import { VanPickerGroup, VanDatePicker } from "vant";
export default {
  components: {
    VanPickerGroup,
    VanDatePicker,
  },
  data() {
    const filtercompanyCode = (input, option) => {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    return {
      filtercompanyCode,
      spinning: false,
      currentPage: "1", //当前页
      total: "", //总条数
      defaultPageSize: "10", //每页几条
      detailsData: {},
      timeShow: false,
      columns: [
        {
          title: "序号",
          key: "index",
          align: "center",
          fixed: "left",
          hideInSetting: true,
          customRender: ({ index }) =>
            (this.currentPage - 1) * 10 + Number(index) + 1,
        },
        {
          title: "出险人名称",
          dataIndex: "customerName",
          align: "center",
          width: 60,
        },
        {
          title: "工作企业",
          dataIndex: "insuranceCompany",
          align: "center",
        },
        {
          title: "受伤程度",
          dataIndex: "hurt",
          align: "center",
          width: 50,
        },
        {
          title: "案件状态",
          dataIndex: "accidentStatus",
          align: "center",
          width: 50,
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          width: 70,
        },
      ],
      dataSource: [],
      form: {
        time: "",
        startDate: [],
        endDate: [],
        startTime: "",
        endTime: "",
        customerName: "",
        companyCode: undefined,
        accidentStatus: undefined,
      },
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2039, 12, 31),
      company: [],
      accidentStatus: [],
      // 复选框
      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(selectedRows);
        },
        getCheckboxProps: (record) => ({
          // 禁用复选框
          // disabled: record.name === "Disabled User",
        }),
      },
    };
  },
  created() {
    getClaimCaseData().then((res) => {
      this.company = res.data.company;
      this.accidentStatus = res.data.accidentStatus;
    });
    this.pagination();
  },
  mounted() {
    const date = new Date();
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const formattedDate = [year, month, day];
    this.form.startDate = formattedDate; // 默认开始日期为当前日期
    this.form.endDate = formattedDate; // 默认结束日期为当前日期
  },
  methods: {
    onFinish() {
      this.pagination();
    },
    lastBtn() {
      this.form.time = "";
      this.form.startDate = [];
      this.form.endDate = [];
      this.form.startTime = "";
      this.form.endTime = "";
      this.form.customerName = "";
      this.form.companyCode = undefined;
      this.form.accidentStatus = undefined;
    },
    // 查看详情
    detailsBtn(record) {
      this.$router.push({
        name: "home/claimInquiry/claimInquiryDetails",
        query: { caseNo: record.caseNo },
      });
    },
    // 选择日期
    onConfirm() {
      this.timeShow = false;
      this.form.time =
        this.form.startDate.join("-") + "至" + this.form.endDate.join("-");
      this.form.startTime = this.form.startDate.join("-");
      this.form.endTime = this.form.endDate.join("-");
    },
    rightIcon() {
      this.form.time = "";
      this.form.startDate = [];
      this.form.endDate = [];
      this.form.startTime = "";
      this.form.endTime = "";
    },
    //分页数据
    pagination() {
      this.spinning = true;
      getClaimCaseList({
        limit: this.defaultPageSize,
        currentPage: this.currentPage,
        customerName: this.form.customerName,
        companyCode: this.form.companyCode,
        accidentStatus: this.form.accidentStatus,
        startTime: this.form.startTime,
        endTime: this.form.endTime,
      }).then((res) => {
        if (res.code == "0") {
          this.total = res.data.total;
          this.dataSource = res.data.records;
        } else if (res.code == "1") {
          setTimeout(() => {
            this.$router.push({
              name: "login",
            });
          }, 1000);
        } else if (res.code == "2") {
          setTimeout(() => {
            this.$router.push({
              name: "login",
            });
          }, 1000);
        }
        this.spinning = false;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
}
.pageBox {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.block {
  width: 90%;
  height: 90%;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  overflow: auto;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}
.ulText {
  width: 100%;
  li {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    p:first-child {
      display: block;
      width: 40%;
      text-align: right;
    }
    p:last-child {
      width: 59%;
    }
  }
}
.bottomBox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0 20px 0;
  button {
    margin: 0 10px;
    height: 35px;
  }
}
/deep/.van-cell {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: var(--van-cell-vertical-padding) var(--van-cell-horizontal-padding);
  overflow: hidden;
  color: var(--van-cell-text-color);
  font-size: var(--van-cell-font-size);
  line-height: var(--van-cell-line-height);
  background: var(--van-cell-background);
  flex-direction: column;
}
/deep/.van-field__body {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  padding: 5px;
  margin-top: 5px;
  box-sizing: border-box;
}
//表头
/deep/.ant-table-thead > tr > th {
  background: rgb(221 235 255) !important;
  font-weight: bold !important;
}
</style>