import axios from 'axios'
import { showToast } from "vant";
const ConfigBaseURL = '/platform'
const token = localStorage.getItem('token')
const service = axios.create({
    timeout: 100000,
    baseURL: ConfigBaseURL,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'token': token, //请求头
    }
})
// 添加请求拦截器
service.interceptors.request.use(
    function (config) {
        // 在发送请求之前做些什么
        return config;
    },
    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);
// 添加响应拦截器
service.interceptors.response.use(
    response => {
        // 对响应数据做点什么
        if (response.data.code == '0') {
            return response.data;
        } else if (response.data.code == '00000') {
            return response.data;
        } else if (response.data.code == '1') {
            showToast("token失效,请重新登录")
            return response.data;
        } else if (response.data.code == '2') {
            showToast("请先登录")
            return response.data;
        } else if (response.data.code == '500') {
            showToast(response.data.msg)
            return response.data;
        } else {
            showToast(response.data.msg)
            return response.data;
        }
    },
    error => {
        showToast(error.data.msg)
        // 对响应错误做点什么
        return Promise.reject(error);
    }
);
export default service;