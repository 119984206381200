<template>
  <div
    style="
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      margin-top: 10px;
    "
  >
    用工企业查询
  </div>
  <a-spin size="large" :spinning="spinning">
    <div style="padding: 10px">
      <div class="container">
        <a-form
          layout="inline"
          :model="form"
          style="margin-bottom: 15px; background: #fff"
          @finish="onFinish"
        >
          <a-row
            :gutter="[0, 12]"
            style="width: 100%; padding: 10px; box-sizing: border-box"
          >
            <a-col span="24">
              <a-form-item>
                <p style="height: 15px" slot="label">
                  <span style="color: red; margin-right: 3px"></span>公司名称
                </p>
                <a-input
                  v-model:value="form.fullCompanyName"
                  style="width: 100%"
                  placeholder="请输入公司名称"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <div class="bottomBox">
            <a-button @click="lastBtn"> 重置 </a-button>
            <a-button
              type="primary"
              style="background: #3e77db"
              html-type="submit"
              >查询</a-button
            >
          </div>
        </a-form>
        <div style="box-sizing: border-box; width: 100%">
          <a-table
            ref="table"
            :columns="columns"
            :data-source="dataSource"
            row-key="id"
            :pagination="false"
            size="middle"
            style="font-size: 13px"
          >
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'action'">
                <a @click="detailsBtn(record)">详情</a>
              </template>
            </template>
          </a-table>
          <!-- 分页 -->
          <div class="pageBox">
            <span style="margin-right: 10px">共{{ total }}条</span>
            <a-pagination
              @change="pagination"
              v-model:current="currentPage"
              v-model:pageSize="defaultPageSize"
              simple
              :total="total"
            />
          </div>
        </div>
      </div>
      <!-- 查看详情弹窗 -->
      <a-modal
        v-model:visible="detailsShow"
        :closable="false"
        :keyboard="false"
        :maskClosable="false"
        width="100%"
      >
        <template
          #title
          style="background: #3e77db !important; color: #fff !important"
        >
          <div
            style="
              text-align: center;
              font-weight: bold;
              font-size: 16px;
              height: 40px;
              line-height: 40px;
              background: linear-gradient(
                to right,
                rgb(221 235 255),
                rgb(252 243 244)
              );
            "
          >
            公司基本信息
          </div>
        </template>
        <template #footer>
          <a-button
            v-if="detailsData.auditStatus == '待确认'"
            type="primary"
            style="background: #3e77db"
            @click="suerBtn"
          >
            <span>确认</span>
          </a-button>
          <a-button key="submit" @click="handleOk">返回</a-button>
        </template>
        <div class="wrapper">
          <div style="flex: 1">
            <div class="textBox" style="padding: 0 24px">
              <a-row>
                <a-col span="9">公司全称：</a-col>
                <a-col span="15">{{ detailsData.fullCompanyName }}</a-col>
              </a-row>
              <van-divider :hairline="false" style="margin: 0" />
              <a-row>
                <a-col span="9">公司简称：</a-col>
                <a-col span="15">{{ detailsData.companyName }}</a-col>
              </a-row>
              <van-divider :hairline="false" style="margin: 0" />
              <a-row>
                <a-col span="9">上级公司名称：</a-col>
                <a-col span="15">{{ detailsData.parentCompanyCode }}</a-col>
              </a-row>
              <van-divider :hairline="false" style="margin: 0" />
              <a-row>
                <a-col span="9">公司性质：</a-col>
                <a-col span="15">{{ detailsData.companyClass }}</a-col>
              </a-row>
              <van-divider :hairline="false" style="margin: 0" />
              <a-row>
                <a-col span="9">行业大类：</a-col>
                <a-col span="15">{{ detailsData.industry }}</a-col>
              </a-row>
              <van-divider :hairline="false" style="margin: 0" />
              <a-row>
                <a-col span="9">行业小类：</a-col>
                <a-col span="15">{{ detailsData.subIndustry }}</a-col>
              </a-row>
              <van-divider :hairline="false" style="margin: 0" />
              <a-row>
                <a-col span="9">人员规模：</a-col>
                <a-col span="15">{{ detailsData.companySize }}</a-col>
              </a-row>
              <van-divider :hairline="false" style="margin: 0" />
              <a-row>
                <a-col span="9">地区：</a-col>
                <a-col span="15">{{ detailsData.province }}</a-col>
              </a-row>
              <van-divider :hairline="false" style="margin: 0" />
              <a-row>
                <a-col span="9">城市：</a-col>
                <a-col span="15">{{ detailsData.city }}</a-col>
              </a-row>
              <van-divider :hairline="false" style="margin: 0" />
              <a-row>
                <a-col span="9">营业执照：</a-col>
                <a-col span="15">{{ detailsData.idNo }}</a-col>
              </a-row>
              <van-divider :hairline="false" style="margin: 0" />
              <a-row>
                <a-col span="9">工作内容描述：</a-col>
                <a-col span="15">{{ detailsData.remark }}</a-col>
              </a-row>
            </div>
            <p
              style="width: 100%; height: 15px; background: #f1f4fb"
              v-if="workPicture != ''"
            ></p>
            <div class="box" style="padding: 0 24px" v-if="workPicture != ''">
              <ul class="ulBox">
                <li><van-icon name="photo-o" size="20" color="#3e77db" /></li>
                <li>图片</li>
              </ul>
              <van-image
                v-for="(item, index) in this.workPicture"
                :key="index"
                :src="item.value"
                style="margin: 3px; width: 20%; height: 100px"
                @click="imageBtn(index)"
              />
            </div>
            <p
              style="width: 100%; height: 15px; background: #f1f4fb"
              v-if="this.workVideo != ''"
            ></p>
            <div style="padding: 0 24px" v-if="this.workVideo != ''">
              <ul class="ulBox">
                <li><van-icon name="video-o" size="20" color="#3e77db" /></li>
                <li>视频</li>
              </ul>
              <div v-for="(item, index) in workVideo" :key="index">
                <van-image
                  :key="index"
                  src="http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20230518155254.jpg"
                  style="margin: 3px; width: 20%; height: 100px; float: left"
                  @click="videoBtn(item)"
                />
              </div>
            </div>
          </div>
        </div>
      </a-modal>
    </div>
  </a-spin>
</template>
<script>
import { showToast } from "vant";
import { showNotify } from "vant";
import { showImagePreview } from "vant";
import {
  queryCompany,
  getCustomerCompanyDetail,
  tCustomerCompanyConfirm,
} from "@/api/api";
export default {
  data() {
    return {
      spinning: false,
      form: {
        fullCompanyName: "",
      },
      currentPage: "1", //当前页
      total: "", //总条数
      defaultPageSize: "10", //每页几条
      // 详情对象
      detailsShow: false,
      detailsData: {},
      workPicture: [],
      workVideo: [],
      columns: [
        {
          title: "序号",
          key: "index",
          align: "center",
          fixed: "left",
          hideInSetting: true,
          customRender: ({ index }) =>
            (this.currentPage - 1) * 10 + Number(index) + 1,
        },
        {
          title: "公司全称",
          dataIndex: "label",
          align: "center",
        },
        {
          title: "行业大类",
          dataIndex: "industry",
          align: "center",
        },
        {
          title: "审核状态",
          dataIndex: "auditStatus",
          align: "center",
        },
        {
          title: "在保人数",
          dataIndex: "appNum",
          align: "center",
        },
        {
          title: "公司分类",
          dataIndex: "companyClass",
          align: "center",
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          width: 45,
        },
      ],
      dataSource: [],
      // 复选框
      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(selectedRows);
        },
        getCheckboxProps: (record) => ({
          // 禁用复选框
          // disabled: record.name === "Disabled User",
        }),
      },
    };
  },
  created() {
    this.pagination();
  },
  methods: {
    // 查看详情
    detailsBtn(record) {
      this.spinning = true;
      getCustomerCompanyDetail({
        companyCode: record.value,
      }).then((res) => {
        this.spinning = false;
        this.detailsShow = true;
        this.detailsData = res.data;
        this.workPicture = res.data.workPicture;
        this.workVideo = res.data.workVideo;
      });
    },
    imageBtn(index) {
      const imgData = [];
      this.workPicture.forEach((v, i) => {
        imgData.push(v.value);
      });
      showImagePreview({
        images: imgData,
        startPosition: index,
        closeable: true,
      });
    },
    videoBtn(item) {
      window.open(item.value, "_blank");
    },
    handleOk() {
      this.detailsShow = false;
    },
    //待确定
    suerBtn() {
      this.spinning = true;
      tCustomerCompanyConfirm(this.detailsData).then((res) => {
        if (res.code == "00000") {
          this.spinning = false;
          this.detailsShow = false;
          showToast(res.data);
          this.pagination();
        } else {
          this.spinning = false;
        }
      });
    },
    // 搜索按钮
    onFinish() {
      this.pagination();
    },
    lastBtn() {
      this.form.fullCompanyName = "";
    },
    //分页数据
    pagination() {
      this.spinning = true;
      queryCompany({
        limit: this.defaultPageSize,
        currentPage: this.currentPage,
        fullCompanyName: this.form.fullCompanyName,
      }).then((res) => {
        if (res.code == "0") {
          this.total = res.list.total;
          this.dataSource = res.list.records;
        } else if (res.code == "1") {
          setTimeout(() => {
            this.$router.push({
              name: "login",
            });
          }, 1000);
        } else if (res.code == "2") {
          setTimeout(() => {
            this.$router.push({
              name: "login",
            });
          }, 1000);
        }
        this.spinning = false;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
}
.pageBox {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.ulBox {
  width: 100%;
  height: 20px;
  line-height: 17px;
  li {
    float: left;
    margin-right: 5px;
  }
}
.textBox {
  .ant-col-9 {
    text-align: left;
    margin: 15px 0;
  }
  .ant-col-15 {
    text-align: left;
    margin: 15px 0;
  }
}
.bottomBox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px 0 20px;
  button {
    margin: 0 10px;
    height: 35px;
  }
}
//表头
/deep/.ant-table-thead > tr > th {
  background: rgb(221 235 255) !important;
  font-weight: bold !important;
}
</style>