<template>
  <div
    style="
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      margin-top: 10px;
    "
  >
    在保人员查询
  </div>
  <a-spin size="large" :spinning="spinning">
    <div style="padding: 10px">
      <div class="container">
        <a-form
          layout="inline"
          :model="form"
          style="margin-bottom: 15px; background: #fff"
          :rules="rules"
          @finish="onFinish"
        >
          <a-row
            :gutter="[0, 12]"
            style="width: 100%; padding: 10px; box-sizing: border-box"
          >
            <a-col span="24">
              <a-form-item>
                <p style="height: 15px" slot="label">
                  <span style="color: red; margin-right: 3px"></span>员工姓名
                </p>
                <a-input
                  v-model:value="form.customerName"
                  style="width: 100%"
                  placeholder="请输入员工姓名"
                />
              </a-form-item>
            </a-col>
            <a-col span="24">
              <a-form-item>
                <p style="height: 15px" slot="label">
                  <span style="color: red; margin-right: 3px"></span
                  >用工企业名称
                </p>
                <a-select
                  ref="select"
                  allowClear
                  show-search
                  :filter-option="filtercompanyCode"
                  v-model:value="form.companyCode"
                  style="width: 100%"
                  :options="companyCodeData"
                  placeholder="请选择用工企业名称"
                ></a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <div class="bottomBox">
            <a-button @click="lastBtn"> 重置 </a-button>
            <a-button
              type="primary"
              style="background: #3e77db"
              html-type="submit"
              >查询</a-button
            >
          </div>
        </a-form>
        <div style="width: 100%">
          <a-table
            :columns="columns"
            :data-source="dataSource"
            row-key="idNo"
            :pagination="false"
            size="middle"
            style="font-size: 13px"
          >
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'action'">
                <a @click="detailsBtn(record)">人员换厂</a>
              </template>
            </template>
          </a-table>
          <!-- 分页 -->
          <div class="pageBox">
            <span style="margin-right: 10px">共{{ total }}条</span>
            <a-pagination
              @change="pagination"
              v-model:current="currentPage"
              v-model:pageSize="defaultPageSize"
              simple
              :total="total"
            />
          </div>
        </div>
      </div>
    </div>
  </a-spin>
</template>
<script>
import { showToast } from "vant";
import { showNotify } from "vant";
import { showImagePreview } from "vant";
import { getValidCustomer, getCompanyByUser } from "@/api/api";
export default {
  data() {
    const filtercompanyCode = (input, option) => {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    return {
      filtercompanyCode,
      spinning: false,
      fullCompanyName: "",
      currentPage: "1", //当前页
      total: "", //总条数
      defaultPageSize: "10", //每页几条
      companyCodeData: [],
      form: {
        customerName: "",
        companyCode: undefined,
      },
      columns: [
        {
          title: "序号",
          key: "index",
          align: "center",
          fixed: "left",
          hideInSetting: true,
          customRender: ({ index }) =>
            (this.currentPage - 1) * 10 + Number(index) + 1,
        },
        {
          title: "员工姓名",
          dataIndex: "customerName",
          align: "center",
          width: 80,
        },
        {
          title: "身份证号码",
          dataIndex: "idNo",
          align: "center",
        },
        {
          title: "用工企业",
          dataIndex: "companyName",
          align: "center",
          width: 140,
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          width: 80,
        },
      ],
      dataSource: [],
      // 复选框
      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(selectedRows);
        },
        getCheckboxProps: (record) => ({
          // 禁用复选框
          // disabled: record.name === "Disabled User",
        }),
      },
    };
  },
  mounted() {
    this.pagination();
    getCompanyByUser().then((res) => {
      this.companyCodeData = res.data;
    });
  },
  methods: {
    onFinish() {
      this.pagination();
    },
    lastBtn() {
      this.form.customerName = "";
      this.form.companyCode = undefined;
    },
    // 人员换厂
    detailsBtn(record) {
      this.$router.push({
        name: "home/personnelQuery/personnelExchange",
        query: record,
      });
    },
    //分页数据
    pagination() {
      this.spinning = true;
      getValidCustomer({
        limit: this.defaultPageSize,
        currentPage: this.currentPage,
        customerName: this.form.customerName,
        companyCode: this.form.companyCode,
      }).then((res) => {
        if (res.code == "0") {
          this.total = res.data.total;
          this.dataSource = res.data.records;
        } else if (res.code == "1") {
          setTimeout(() => {
            this.$router.push({
              name: "login",
            });
          }, 1000);
        } else if (res.code == "2") {
          setTimeout(() => {
            this.$router.push({
              name: "login",
            });
          }, 1000);
        }
        this.spinning = false;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
}
.pageBox {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.block {
  width: 90%;
  height: 90%;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  overflow: auto;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}
.bottomBox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px 0 20px;
  button {
    margin: 0 10px;
    height: 35px;
  }
}
.ulText {
  width: 100%;
  li {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    p:first-child {
      display: block;
      width: 40%;
      text-align: right;
    }
    p:last-child {
      width: 59%;
    }
  }
}
//表头
/deep/.ant-table-thead > tr > th {
  background: rgb(221 235 255) !important;
  font-weight: bold !important;
}
</style>