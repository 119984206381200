<template>
  <div class="container">
    <div style="height: 38%"></div>
    <div class="loginTitle">全时保投保系统</div>
    <div class="loginBox">
      <a-form
        :model="formState"
        name="normal_login"
        class="login-form"
        @finish="onFinish"
      >
        <a-form-item name="account">
          <a-input v-model:value="formState.account">
            <template #prefix>
              <UserOutlined class="site-form-item-icon" />
            </template>
          </a-input>
        </a-form-item>
        <a-form-item name="password">
          <a-input-password v-model:value="formState.password">
            <template #prefix>
              <LockOutlined class="site-form-item-icon" />
            </template>
          </a-input-password>
        </a-form-item>
        <a-form-item>
          <a-form-item name="flag" no-style>
            <a-checkbox v-model:checked="formState.flag">记住密码</a-checkbox>
          </a-form-item>
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            html-type="submit"
            class="login-form-button"
            style="
              background: #3e77db;
              border: #3e77db;
              border-radius: 10px;
              height: 40px;
            "
            block
          >
            登录
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
<script>
import { UserOutlined, LockOutlined } from "@ant-design/icons-vue";
import { showToast } from "vant";
import { showNotify } from "vant";
import { login, userBehavior } from "@/api/api";
export default {
  components: {
    UserOutlined,
    LockOutlined,
  },
  data() {
    return {
      formState: {
        account: "",
        password: "",
        flag: true,
      },
    };
  },
  methods: {
    onFinish(values) {
      if (values.account == "") {
        showToast("请输入账号");
      } else if (values.password == "") {
        showToast("请输入密码");
      } else {
        login(values).then((res) => {
          if (res.code == "0") {
            localStorage.setItem("token", res.token);
            localStorage.setItem("user", JSON.stringify(res.user));
            localStorage.setItem("userInfo", JSON.stringify(res.userInfo));
            showNotify({ type: "success", message: "登录成功" });
            userBehavior({ stayPage: "login" });
            setTimeout(() => {
              if (window.history.length <= 1) {
                this.$router.push({ name: "home" });
                return false;
              } else {
                this.$router.go(-1);
              }
            }, 1000);
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0px;
  background: url("http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E7%99%BB%E5%BD%95%E8%83%8C%E6%99%AF%E5%9B%BE.jpg")
    #e8f1fa no-repeat;
  background-size: 100% auto;
  background-attachment: fixed;
  padding: 10px;
  box-sizing: border-box;
}
.loginTitle {
  background: linear-gradient(to right, #3e77db, #829de4, #3e77db);
  text-align: center;
  color: rgba(255, 255, 255, 0.938);
  font-weight: bold;
  font-size: 19px;
  letter-spacing: 3px;
  height: 50px;
  line-height: 50px;
  border-radius: 15px 15px 0 0;
}
.loginBox {
  width: 100%;
  border-radius: 5px;
  background: #fff;
  padding: 10px 30px;
  box-sizing: border-box;
}
.ant-form-item {
  margin-bottom: 14px !important;
}
</style>