<template>
  <div
    style="
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      margin-top: 10px;
    "
  >
    加减保记录
  </div>
  <a-spin size="large" :spinning="spinning">
    <div style="padding: 10px">
      <div class="container">
        <div class="topBox">
          <ul>
            <li>投保产品：</li>
            <li>{{ productName }}</li>
          </ul>
          <ul>
            <li>投保方案：</li>
            <li>{{ planName }}</li>
          </ul>
          <ul>
            <li>生效日期：</li>
            <li>{{ timeStamp }}</li>
          </ul>
        </div>
        <div style="box-sizing: border-box; width: 100%">
          <a-table
            :columns="columns"
            :data-source="dataSource"
            row-key="idNo"
            :pagination="false"
            size="middle"
            style="font-size: 13px"
          >
          </a-table>
          <!-- 分页 -->
          <div class="pageBox">
            <span style="margin-right: 10px">共{{ total }}条</span>
            <a-pagination
              @change="pagination"
              v-model:current="currentPage"
              v-model:pageSize="defaultPageSize"
              simple
              :total="total"
            />
          </div>
        </div>
      </div>
    </div>
  </a-spin>
</template>
<script>
import { showToast } from "vant";
import { showNotify } from "vant";
import { getChangeDetail, getChangeData } from "@/api/api";
export default {
  data() {
    return {
      spinning: false,
      currentPage: "1", //当前页
      total: "", //总条数
      defaultPageSize: "10", //每页几条
      planName: "",
      productName: "",
      timeStamp: "",
      columns: [
        {
          title: "姓名",
          dataIndex: "customerName",
          align: "center",
        },
        {
          title: "身份证号码",
          dataIndex: "idNo",
          align: "center",
        },
        {
          title: "用工企业",
          dataIndex: "companyName",
          align: "center",
        },
        {
          title: "操作时间",
          dataIndex: "timeStamp",
          align: "center",
        },
      ],
      dataSource: [],
      // 复选框
      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(selectedRows);
        },
        getCheckboxProps: (record) => ({
          // 禁用复选框
          // disabled: record.name === "Disabled User",
        }),
      },
    };
  },
  created() {
    this.pagination();
  },
  methods: {
    //分页数据
    pagination() {
      this.spinning = true;
      getChangeDetail({
        limit: this.defaultPageSize,
        currentPage: this.currentPage,
        productCode: this.$route.query.productCode,
        productName: this.$route.query.productName,
        changeNum: this.$route.query.changeNum,
        changeType: this.$route.query.changeType,
        proChgId: this.$route.query.proChgId,
        timeStamp: this.$route.query.timeStamp,
      }).then((res) => {
        console.log(res);
        if (res.code == "0") {
          this.total = res.data.total;
          this.dataSource = res.data.records;
          this.planName = res.planName;
          this.productName = res.productName;
          this.timeStamp = res.timeStamp;
        } else if (res.code == "1") {
          setTimeout(() => {
            this.$router.push({
              name: "login",
            });
          }, 1000);
        } else if (res.code == "2") {
          setTimeout(() => {
            this.$router.push({
              name: "login",
            });
          }, 1000);
        }
        this.spinning = false;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
}
.pageBox {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.block {
  width: 90%;
  height: 90%;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  overflow: auto;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}
.topBox {
  text-align: center;
  background: #fff;
  padding: 10px;
  box-sizing: border-box;
  ul {
    display: flex;
    text-align: center;
    justify-content: center;
    width: 100%;
    li:first-child {
      width: 120px;
      text-align: left;
    }
    li:last-child {
      flex: 1;
      text-align: left;
    }
  }
}
.ulText {
  width: 100%;
  li {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    p:first-child {
      display: block;
      width: 40%;
      text-align: right;
    }
    p:last-child {
      width: 59%;
    }
  }
}
//表头
/deep/.ant-table-thead > tr > th {
  background: rgb(221 235 255) !important;
  font-weight: bold !important;
}
</style>v