<template>
  <div
    style="
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      margin-top: 10px;
    "
  >
    充值
  </div>
  <a-spin size="large" :spinning="spinning">
    <div class="recharge">
      <div style="background: #fff; padding: 10px">
        <p style="height: 15px" slot="label">
          <span style="color: red; margin-right: 3px">*</span>充值金额
        </p>
        <div class="amount-row">
          <div
            v-for="option in options"
            :key="option.value"
            class="amount-item"
            :class="{ active: selectedAmount === option.value }"
            @click="selectAmount(option)"
          >
            {{ option.label }}
          </div>
        </div>
        <a-modal
          v-model:visible="dialogVisible"
          :closable="false"
          :keyboard="false"
        >
          <template
            #title
            style="background: #3e77db !important; color: #fff !important"
          >
            <div
              style="
                text-align: center;
                font-weight: bold;
                font-size: 16px;
                height: 40px;
                line-height: 40px;
                background: linear-gradient(
                  to right,
                  rgb(221 235 255),
                  rgb(252 243 244)
                );
              "
            >
              充值金额
            </div>
          </template>
          <template #footer>
            <a-button key="back" @click="handleCancel">取消</a-button>
            <a-button
              key="submit"
              type="primary"
              :loading="loading"
              style="background: #3e77db; border: none"
              @click="handleOk"
              >确定</a-button
            >
          </template>
          <a-form-item style="padding: 24px">
            <p style="height: 15px" slot="label">
              <span style="color: red; margin-right: 3px"></span>金额
            </p>
            <a-input
              v-model:value="inputValue"
              placeholder="请输入金额"
              @input="onInput"
            />
          </a-form-item>
        </a-modal>
        <van-button
          type="info"
          style="margin: 10px 0; border: none; border-radius: 8px"
          block
          color="#3e77db"
          @click="tpPayBtn"
          >充值</van-button
        >
      </div>
    </div>
  </a-spin>
</template>
<script>
import { showToast } from "vant";
import { toPay } from "@/api/api";
export default {
  data() {
    return {
      selectedAmount: "",
      inputValue: "",
      money: "",
      dialogVisible: false,
      spinning: false,
      options: [
        { value: "1000", label: "1000元" },
        { value: "2000", label: "2000元" },
        { value: "3000", label: "3000元" },
        { value: "5000", label: "5000元" },
        { value: "10000", label: "10000元" },
        { value: "", label: "其他金额", text: "输入" },
      ],
    };
  },
  methods: {
    selectAmount(amount) {
      this.selectedAmount = amount.value;
      if (amount.text == "输入") {
        this.dialogVisible = true;
      }
    },
    onInput(event) {
      // 过滤非数字字符，只保留正整数
      let filteredValue = event.target.value.replace(/[^\d]/g, "");
      if (filteredValue === "") {
        // 如果输入的是空字符串，直接设置输入框的值为空
        this.inputValue = "";
      } else {
        // 否则将输入框的值设置为过滤后的正整数字符串
        this.inputValue = parseInt(filteredValue);
      }
    },
    handleCancel() {
      this.dialogVisible = false;
      this.inputValue = "";
    },
    handleOk() {
      const otherAmountOptionIndex = this.options.findIndex(
        (option) => option.text === "输入"
      );
      if (otherAmountOptionIndex !== -1) {
        this.money = this.inputValue.toString();
        if (this.inputValue !== "") {
          const amount = parseInt(this.inputValue);
          this.options[otherAmountOptionIndex].label = amount.toString() + "元";
        }
      }
      this.dialogVisible = false;
      this.inputValue = "";
    },
    tpPayBtn() {
      if (this.money == "" && this.selectedAmount == "") {
        showToast("请选择金额或者输入金额");
      } else {
        if (this.selectedAmount) {
          this.spinning = true;
          toPay({ totalAmount: this.selectedAmount * 1 }).then((res) => {
            if (res.code == "0") {
              window.location.href = res.payMentInfo.qrCode;
            }
            this.spinning = false;
          });
        } else {
          this.spinning = true;
          toPay({ totalAmount: this.money * 1 }).then((res) => {
            if (res.code == "0") {
              window.location.href = res.payMentInfo.qrCode;
            }
            this.spinning = false;
          });
        }
      }
    },
  },
};
</script>
<style scoped>
.recharge {
  max-width: 600px;
  margin: 0 auto;
  padding: 10px;
}

.amount-row {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}

.amount-item {
  flex: 0 0 calc(33.33% - 10px);
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 10px;
  text-align: center;
  border: 1px solid #ccc;
  font-weight: bold;
  color: #555;
  cursor: pointer;
}

.amount-item.active {
  border-color: #3e77db;
  color: #3e77db;
}

.other-amount-row {
  margin-bottom: 10px;
}

.custom-amount {
  display: block;
  width: 100%;
  padding: 6px 10px;
  border: 1px solid #ccc;
  min-width: 60px;
  cursor: text;
}

.recharge-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #3e77db;
  color: #fff;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.recharge-button:hover {
  background-color: #3e77db;
}
</style>
  