<template>
  <div
    class="floating-window"
    :style="{ top: posY + 'px', left: posX + 'px' }"
    @mousedown="startDrag"
    @touchstart="startDrag"
    @mouseup="stopDrag"
    @touchend="stopDrag"
  >
    <img src="@/assets/goHome.gif" style="width: 60px; height: auto" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      posX: 0,
      posY: 0,
      isDragging: false,
      initialX: 0,
      initialY: 0,
      longClick: 0,
      clientX: 0,
      clientY: 0,
    };
  },
  mounted() {
    this.setInitialPosition();
  },
  methods: {
    setInitialPosition() {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const windowPadding = 20;
      const floatingWindowWidth = 40;
      const floatingWindowHeight = 300;

      this.posX = windowWidth - floatingWindowWidth - windowPadding;
      this.posY = windowHeight - floatingWindowHeight - windowPadding;
    },
    startDrag(event) {
      event.preventDefault();
      this.isDragging = true;
      this.time = Date.now();
      // 根据事件类型选择相应的属性
      const clientX =
        event.type === "touchstart" ? event.touches[0].clientX : event.clientX;
      const clientY =
        event.type === "touchstart" ? event.touches[0].clientY : event.clientY;
      this.clientX = clientX;
      this.clientY = clientY;
      this.initialX = clientX - this.posX;
      this.initialY = clientY - this.posY;

      document.addEventListener("mousemove", this.handleDrag);
      document.addEventListener("touchmove", this.handleDrag);
      document.addEventListener("mouseup", this.stopDrag);
      document.addEventListener("touchend", this.stopDrag);
    },
    handleDrag(event) {
      event.preventDefault();
      if (this.isDragging) {
        const clientX =
          event.type === "touchmove" ? event.touches[0].clientX : event.clientX;
        const clientY =
          event.type === "touchmove" ? event.touches[0].clientY : event.clientY;
        const offsetX = clientX - this.initialX;
        const offsetY = clientY - this.initialY;

        this.posX = Math.max(
          0,
          Math.min(offsetX, window.innerWidth - this.$el.offsetWidth)
        );
        this.posY = Math.max(
          0,
          Math.min(offsetY, window.innerHeight - this.$el.offsetHeight)
        );
      }
    },
    stopDrag(event) {
      event.preventDefault();
      this.isDragging = false;

      document.removeEventListener("mousemove", this.handleDrag);
      document.removeEventListener("touchmove", this.handleDrag);
      document.removeEventListener("mouseup", this.stopDrag);
      document.removeEventListener("touchend", this.stopDrag);
    },
    stopDrag(event) {
      event.preventDefault();
      this.isDragging = false;

      const clientX =
        event.type === "touchend"
          ? event.changedTouches[0].clientX
          : event.clientX;
      const clientY =
        event.type === "touchend"
          ? event.changedTouches[0].clientY
          : event.clientY;

      if (this.clientX == clientX && this.clientY == clientY) {
        this.$router.push({ name: "home" });
      }
      document.removeEventListener("mousemove", this.handleDrag);
      document.removeEventListener("touchmove", this.handleDrag);
      document.removeEventListener("mouseup", this.stopDrag);
      document.removeEventListener("touchend", this.stopDrag);
    },
  },
};
</script>
<style>
.floating-window {
  position: fixed;
  width: 60px;
  height: 60px;
  z-index: 999;
  /* 其他样式 */
}
</style>
  