<template>
  <div id="app">
    <div>
      <router-view />
      <float />
    </div>
    <van-tabbar
      v-model="active"
      active-color="rgb(33 67 163)"
      inactive-color="rgb(163 163 163)"
      v-if="$route.meta.keepalive == true"
    >
      <van-tabbar-item to="/">
        <span>首页</span>
        <template #icon="props">
          <img :src="props.active ? icon.active : icon.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/product">
        <span>产品</span>
        <template #icon="props">
          <img :src="props.active ? icon1.active : icon1.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/mine">
        <span>我的</span>
        <template #icon="props">
          <img :src="props.active ? icon2.active : icon2.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import float from "./views/float";
export default {
  components: {
    float
  },
  name: "App",
  data() {
    return {
      icon: {
        active:
          "http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E9%A6%96%E9%A1%B51.png",
        inactive:
          "http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E9%A6%96%E9%A1%B52.png"
      },
      icon1: {
        active:
          "http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E4%BA%A7%E5%93%811.png",
        inactive:
          "http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E4%BA%A7%E5%93%812.png"
      },
      icon2: {
        active:
          "http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E6%88%91%E7%9A%841.png",
        inactive:
          "http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E6%88%91%E7%9A%842.png"
      }
    };
  },
  watch: {
    $route(to, form) {
      if (this.$route.path == "/") {
        this.active = 0;
      } else if (this.$route.path == "/product") {
        this.active = 1;
      } else if (this.$route.path == "/mine") {
        this.active = 2;
      }
    }
  },

  methods: {}
};
</script>

<style lang="less">
body,
html {
  width: 100%;
  height: 100%;
  box-sizing: border-box !important;
  touch-action: manipulation !important; /* 禁用双指手势缩放 */
  background: linear-gradient(to right, rgb(221 235 255), rgb(252 243 244));
}

.ant-modal-header {
  padding: 0 !important;
}
.ant-modal-body {
  padding: 0 !important;
}
.ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none !important;
  border-bottom: 1px solid #d9d9d9 !important;
}
.ant-picker {
  border: none !important;
  border-bottom: 1px solid #d9d9d9 !important;
}
.ant-input {
  border: none !important;
  border-bottom: 1px solid #d9d9d9 !important;
}
.ant-input-affix-wrapper {
  border: none !important;
  border-bottom: 1px solid #d9d9d9 !important;
}
.ant-input-number {
  border: none !important;
  border-bottom: 1px solid #d9d9d9 !important;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.bottomBox button[data-v-14812a1c] {
  border-radius: 8px !important;
  height: 45px !important;
  border: none !important;
}
.bottomBox button[data-v-482c95f5] {
  border-radius: 8px !important;
  height: 45px !important;
  border: none !important;
}
.bottomBox button[data-v-20a81cde] {
  border-radius: 8px !important;
  height: 45px !important;
  border: none !important;
}
.bottomBox button[data-v-53c11e12] {
  border-radius: 8px !important;
  height: 45px !important;
  border: none !important;
}
.bottomBox button[data-v-216cc59a] {
  border-radius: 8px !important;
  height: 45px !important;
  border: none !important;
}
#app {
  padding: 0;
  margin: 0;
  font-size: 14px;
  box-sizing: border-box !important;
}
</style>
