<template>
  <div class="container">
    <van-tabs v-model="active" color="#3e77db" sticky>
      <van-tab title="雇主责任险">
        <img
          src="http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E4%BA%A7%E5%93%81/%E9%9B%87%E4%B8%BB%E8%B4%A3%E4%BB%BB%E9%99%A93.jpg"
        />
      </van-tab>
      <van-tab title="团体意外险">
        <img
          src="http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E4%BA%A7%E5%93%81/%E5%9B%A2%E4%BD%93%E6%84%8F%E5%A4%96%E9%99%A9.jpg"
        />
      </van-tab>
      <van-tab title="其他保险">
        <img
          src="http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E4%BA%A7%E5%93%81/%E5%85%B6%E4%BB%96%E4%BF%9D%E9%99%A9%E4%BA%A7%E5%93%81.jpg"
        />
      </van-tab>
    </van-tabs>
    <div style="height: 3.5rem"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
}
img {
  width: 100%;
  height: auto;
  display: block;
}
</style>