<template>
  <div class="container">
    <div class="tabBox">
      <!-- 头图 -->
      <img
        src="http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20230518150949.jpg"
        style="width: 100%; height: auto; display: block; margin-bottom: 15px"
      />
      <div class="imgTitle">
        <img
          src="http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E9%A6%96%E9%A1%B5/titleIcon/%E5%8A%A0%E4%BF%9D%403x.jpg"
          @click="addInsure"
        />
        <img
          src="http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E9%A6%96%E9%A1%B5/titleIcon/%E5%87%8F%E4%BF%9D%403x.png"
          @click="deleteInsure"
        />
        <img
          src="http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E9%A6%96%E9%A1%B5/titleIcon/%E5%8A%A0%E5%87%8F%E8%AE%B0%E5%BD%95%403x.jpg"
          @click="insuranceRecords"
        />
        <img
          src="http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E9%A6%96%E9%A1%B5/titleIcon/%E5%9C%A8%E7%BA%BF%E6%8A%A5%E6%A1%883x.jpg"
          @click="onlineReporting"
        />
        <img
          src="http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E9%A6%96%E9%A1%B5/titleIcon/%E7%90%86%E8%B5%94%E6%9F%A5%E8%AF%A2%403x.jpg"
          @click="claimInquiry"
        />
      </div>
      <div class="imgTitle" style="margin-top: 10px; margin-bottom: 5px">
        <img
          src="http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E9%A6%96%E9%A1%B5/titleIcon/%E5%9C%A8%E4%BF%9D%E6%9F%A5%E8%AF%A2%403x.png"
          @click="personnelQuery"
        />
        <img
          src="http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E9%A6%96%E9%A1%B5/titleIcon/%E7%94%A8%E5%B7%A5%E6%9F%A5%E8%AF%A2%403x.jpg"
          @click="queryCompany"
        />
        <img
          src="http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E9%A6%96%E9%A1%B5/titleIcon/%E6%96%B0%E5%A2%9E%E7%94%A8%E5%B7%A5%E6%9F%A5%E8%AF%A2%403x.jpg"
          @click="addCompany"
        />
        <img
          src="http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E9%A6%96%E9%A1%B5/titleIcon/%E5%85%85%E5%80%BC%403x.jpg"
          @click="toPay"
        />
        <img
          src="http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E9%A6%96%E9%A1%B5/titleIcon/%E6%B6%88%E8%B4%B9%E8%AE%B0%E5%BD%95%403x.jpg"
          @click="consumptionRecords"
        />
      </div>
    </div>
    <div style="width: 100%; background: #fff; margin: 10px 0">
      <div
        style="
          display: flex;
          align-items: center;
          font-weight: bold;
          font-size: 16px;
          padding: 10px;
          height: 40px;
        "
      >
        <div>
          <img
            src="http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E9%A6%96%E9%A1%B5/%E6%96%B0%E9%97%BB.png"
            style="width: 19px; height: auto; display: block; margin-right: 5px"
          />
        </div>
        <div>保险科普</div>
      </div>
      <div
        v-for="(item, index) in records"
        :key="index"
        style="margin-top: 10px"
      >
        <ul
          style="
            display: flex;
            width: 100%;
            height: 80px;
            justify-content: space-between;
            align-items: center;
            padding: 0px 10px;
            box-sizing: border-box;
            margin: -5px 0;
          "
          @click="titleBtn(item)"
        >
          <li
            style="
              width: 65%;
              height: 80px;
              line-height: 10px;
              padding-top: 18px;
            "
          >
            <p style="font-weight: bold">
              {{ item.newsSubTitle }}
            </p>
          </li>
          <img
            :src="item.imageUrl"
            style="width: auto; height: 100%; display: block"
          />
        </ul>
        <van-divider :hairline="false" />
      </div>
      <div class="pageBox">
        <span style="margin-right: 10px">共{{ total }}条</span>
        <!-- 分页 -->
        <a-pagination
          @change="pagination"
          v-model:current="currentPage"
          v-model:pageSize="defaultPageSize"
          simple
          :total="total"
        />
      </div>
    </div>
    <img
      src="http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E9%A6%96%E9%A1%B5/%E5%85%AC%E5%8F%B8%E7%AE%80%E4%BB%8B.png"
    />
    <div style="height: 3.5rem"></div>
  </div>
</template>
<script>
import { newsList, userBehavior } from "@/api/api";
export default {
  data() {
    return {
      currentPage: 1, //当前页
      total: "", //总条数
      defaultPageSize: 3, //每页几条
      records: [],
      newsVideo: "",
    };
  },
  created() {
    this.pagination();
  },
  methods: {
    titleBtn(item) {
      this.$router.push({
        name: "home/imgUrl",
        query: {
          jumpUrl: item.jumpUrl,
        },
      });
    },
    //分页数据
    pagination() {
      newsList({
        currentPage: this.currentPage,
        limit: this.defaultPageSize,
      }).then((res) => {
        if (res.code == "0") {
          this.total = res.list.total;
          this.records = res.list.records;
          this.newsVideo = res.url.newsVideo;
        } else if (res.code == "1") {
          this.$router.push({
            name: "login",
          });
        } else if (res.code == "2") {
          this.$router.push({
            name: "login",
          });
        }
      });
    },
    // 加保
    addInsure() {
      userBehavior({ stayPage: "addInsure" });
      this.$router.push({
        name: "home/addInsure",
      });
    },
    // 减保
    deleteInsure() {
      userBehavior({ stayPage: "deleteInsure" });
      this.$router.push({
        name: "home/deleteInsure",
      });
    },
    // 加减保记录
    insuranceRecords() {
      userBehavior({ stayPage: "insuranceRecords" });
      this.$router.push({
        name: "home/insuranceRecords",
      });
    },
    // 新增用工企业
    addCompany() {
      userBehavior({ stayPage: "addCompany" });
      this.$router.push({
        name: "home/addCompany",
      });
    },
    // 在线报案
    onlineReporting() {
      userBehavior({ stayPage: "onlineReporting" });
      this.$router.push({
        name: "home/onlineReporting",
      });
    },
    // 理赔案件查询
    claimInquiry() {
      userBehavior({ stayPage: "claimInquiry" });
      this.$router.push({
        name: "home/claimInquiry",
      });
    },
    // 在保人员查询
    personnelQuery() {
      userBehavior({ stayPage: "personnelQuery" });
      this.$router.push({
        name: "home/personnelQuery",
      });
    },
    // 用工企业查询
    queryCompany() {
      userBehavior({ stayPage: "queryCompany" });
      this.$router.push({
        name: "home/queryCompany",
      });
    },
    // 充值
    toPay() {
      userBehavior({ stayPage: "toPay" });
      this.$router.push({
        name: "home/toPay",
      });
    },
    // 费用清单
    consumptionRecords() {
      userBehavior({ stayPage: "consumptionRecords" });
      this.$router.push({
        name: "home/consumptionRecords",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
}
img {
  width: 100%;
  height: auto;
  display: block;
}
.imgTitle {
  img {
    padding: 4px;
    box-sizing: border-box;
  }
}
.tabBox {
  width: 100%;
  background: #fff;
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 20%;
      height: auto;
      display: block;
    }
  }
}
.pageBox {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
}
/deep/.van-cell__title {
  flex: none;
  width: 75% !important;
}
/deep/.van-cell__value {
  width: 25% !important;
}
</style>