import request from "@/utils/request";

// 登录接口
export function login(data) {
    return request({
        url: '/app/user/login',
        method: 'post',
        data
    })
}
// 登录后查询余额、人数、案件接口
export function userInfo(data) {
    return request({
        url: '/app/user/userInfo',
        method: 'post',
        data
    })
}
//用户行为记录
export function userBehavior(data) {
    return request({
        url: '/app/user/userBehavior',
        method: 'post',
        data
    })
}
// 首页实时新闻接口
export function newsList(data) {
    return request({
        url: '/app/news/list',
        method: 'post',
        data
    })
}
// 省市接口
export function areaQuery(data) {
    return request({
        url: '/app/area/AreaQuery',
        method: 'post',
        data
    })
}
// 新增用工企业下拉框接口
export function getCustomerCompanyData(data) {
    return request({
        url: '/app/company/getCustomerCompanyData',
        method: 'get',
        data
    })
}
// 新增用工企业保存接口
export function addCompany(data) {
    return request({
        url: '/app/company/addCompany',
        method: 'post',
        data
    })
}
// 用工企业查询接口
export function queryCompany(data) {
    return request({
        url: '/app/company/getCustomerCompanyList',
        method: 'post',
        data
    })
}
// 用工企业查询确认接口
export function tCustomerCompanyConfirm(data) {
    return request({
        url: '/app/company/tCustomerCompanyConfirm',
        method: 'post',
        data
    })
}
// 用工企业查看详情接口
export function getCustomerCompanyDetail(data) {
    return request({
        url: '/app/company/getCustomerCompanyDetail',
        method: 'post',
        data
    })
}
// 加保投保产品、方案下拉框接口
export function getProposal(data) {
    return request({
        url: '/app/proposal/getProposal',
        method: 'get',
        data
    })
}
// 加保工种设置接口
export function getProductCareeCategory(data) {
    return request({
        url: '/app/careeCategory/getProductCareeCategory ',
        method: 'post',
        data
    })
}
// 加保身份证扫描接口
export function idCardOCR(data) {
    return request({
        url: '/app/OCR/idCardOCR',
        method: 'post',
        data
    })
}
// 加保加人接口
export function addCustomer(data) {
    return request({
        url: '/app/customer/addCustomer',
        method: 'post',
        data
    })
}
// 加保确认接口
export function addProposal(data) {
    return request({
        url: '/app/proposalChange/addProposal',
        method: 'post',
        data
    })
}
// 减保用工企业下拉框接口
export function getCompanyByNo(data) {
    return request({
        url: '/app/company/getCompanyByNo',
        method: 'post',
        data
    })
}
// 减保查询人员列表接口
export function getProposalCustomer(data) {
    return request({
        url: '/app/customer/getProposalCustomer',
        method: 'post',
        data
    })
}
// 减保确认接口
export function delProposal(data) {
    return request({
        url: '/app/proposalChange/delProposal',
        method: 'post',
        data
    })
}
// 加减保记录下拉框接口
export function getChangeData(data) {
    return request({
        url: '/app/proposalChange/getChangeData',
        method: 'post',
        data
    })
}
// 加减保记录查询接口
export function getProposalChange(data) {
    return request({
        url: '/app/proposalChange/getProposalChange',
        method: 'post',
        data
    })
}
// 加减保记录详情接口
export function getChangeDetail(data) {
    return request({
        url: '/app/proposalChange/getChangeDetail',
        method: 'post',
        data
    })
}
// 支付接口
export function toPay(data) {
    return request({
        url: '/app/payment/charge',
        method: 'post',
        data
    })
}
// 消费查询接口
export function queryDaliy(data) {
    return request({
        url: '/app/payment/QueryDaliy',
        method: 'post',
        data
    })
}
// 在保人员查询下拉框接口
export function getCompanyByUser(data) {
    return request({
        url: '/app/company/getCompanyByUser',
        method: 'post',
        data
    })
}
// 在保人员查询接口
export function getValidCustomer(data) {
    return request({
        url: '/app/proposalInsurance/getValidCustomer',
        method: 'post',
        data
    })
}
// 在保人员换厂查询接口
export function companyChange(data) {
    return request({
        url: '/app/companyChange/getChangeData',
        method: 'post',
        data
    })
}
// 在保人员换厂确认接口
export function changeCompany(data) {
    return request({
        url: '/app/companyChange/changeCompany',
        method: 'post',
        data
    })
}
// 身份证上传接口
export function cardOCR(data) {
    return request({
        url: '/app/OCR/cardOCR',
        method: 'post',
        data
    })
}
// 理赔查询下拉框
export function getClaimCaseData(data) {
    return request({
        url: '/app/claimCase/getClaimCaseData',
        method: 'post',
        data
    })
}
// 理赔查询
export function getClaimCaseList(data) {
    return request({
        url: '/app/claimCase/getClaimCaseList',
        method: 'post',
        data
    })
}
// 理赔查询进度查看
export function claimCaseAccidentProgress(data) {
    return request({
        url: '/app/claimCase/claimCaseAccidentProgress',
        method: 'post',
        data
    })
}
// 在线报案下拉框
export function getSelectorData(data) {
    return request({
        url: '/app/claimCase/getSelectorData',
        method: 'get',
        data
    })
}
// 在线报案确认
export function claimCaseAdd(data) {
    return request({
        url: '/app/claimCase/claimCaseAdd',
        method: 'post',
        data
    })
}