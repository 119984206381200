<template>
  <div
    style="
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      margin-top: 10px;
    "
  >
    人员换厂
  </div>
  <a-spin size="large" :spinning="spinning">
    <div style="padding: 10px">
      <div class="container">
        <a-table
          :columns="columns"
          :data-source="dataSource"
          row-key="idNo"
          :pagination="false"
          size="middle"
          style="font-size: 13px"
        >
        </a-table>
        <div
          style="
            margin-top: 10px;
            background: #fff;
            padding: 10px;
            box-sizing: border-box;
          "
        >
          <a-form
            layout="inline"
            :model="form"
            style="margin-bottom: 15px"
            @finish="onFinish"
          >
            <a-row :gutter="[0, 24]">
              <a-col span="24">
                <a-form-item>
                  <p style="height: 15px" slot="label">
                    <span style="color: red; margin-right: 3px">*</span>投保产品
                  </p>
                  <a-input
                    readOnly
                    v-model:value="form.productName"
                    style="width: 100%"
                  />
                </a-form-item>
              </a-col>
              <a-col span="24">
                <a-form-item>
                  <p style="height: 15px" slot="label">
                    <span style="color: red; margin-right: 3px">*</span>投保方案
                  </p>
                  <a-input
                    readOnly
                    v-model:value="form.planName"
                    style="width: 100%"
                  />
                </a-form-item>
              </a-col>
              <a-col span="24">
                <a-form-item>
                  <p style="height: 15px" slot="label">
                    <span style="color: red; margin-right: 3px">*</span>用工企业
                  </p>
                  <a-select
                    ref="select"
                    allowClear
                    show-search
                    :filter-option="filtercompanyCode"
                    v-model:value="form.companyCode"
                    style="width: 100%"
                    :options="company"
                    @change="categoryChange"
                    placeholder="请选择用工企业"
                  ></a-select>
                </a-form-item>
              </a-col>
              <a-col span="24">
                <a-form-item>
                  <p style="height: 15px" slot="label">
                    <span style="color: red; margin-right: 3px">*</span>所属工种
                  </p>
                  <a-select
                    ref="select"
                    allowClear
                    show-search
                    :filter-option="filtercategory"
                    v-model:value="form.category"
                    style="width: 100%"
                    :options="category"
                    @click="categoryClick"
                    placeholder="请选择所属工种"
                  ></a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <div class="bottomBox">
              <a-button type="primary" block html-type="submit"
                >换厂确认</a-button
              >
            </div>
          </a-form>
        </div>
      </div>
    </div>
  </a-spin>
</template>
<script>
import { showToast } from "vant";
import { showNotify } from "vant";
import { showImagePreview } from "vant";
import { companyChange, changeCompany } from "@/api/api";
export default {
  data() {
    const filtercompanyCode = (input, option) => {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    const filtercategory = (input, option) => {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    return {
      filtercompanyCode,
      filtercategory,
      spinning: false,
      columns: [
        {
          title: "员工姓名",
          dataIndex: "customerName",
          align: "center",
        },
        {
          title: "身份证号码",
          dataIndex: "idNo",
          align: "center",
        },
        {
          title: "用工企业",
          dataIndex: "companyName",
          align: "center",
        },
      ],
      dataSource: [],
      form: {
        productName: "",
        planName: "",
        companyCode: undefined,
        category: undefined,
      },
      company: [],
      category: [],
    };
  },
  mounted() {
    this.spinning = true;
    this.dataSource.push(this.$route.query);
    companyChange({ proposalNo: this.$route.query.proposalNo }).then((res) => {
      this.form.productName = res.data.productName;
      this.form.planName = res.data.planName;
      this.company = res.data.company;
      this.spinning = false;
    });
  },
  methods: {
    categoryClick() {
      if (this.category.length == 0) {
        showToast("请先选择用工企业");
      }
    },
    categoryChange() {
      this.form.category = undefined;
      if (this.form.companyCode != undefined) {
        companyChange({
          proposalNo: this.$route.query.proposalNo,
          companyCode: this.form.companyCode,
        }).then((res) => {
          this.category = res.data.category;
        });
      } else {
        this.category = undefined;
      }
    },
    onFinish() {
      if (this.form.companyCode == undefined) {
        showToast("请选择用工企业");
      } else if (this.form.category == undefined) {
        showToast("请选择所属工种");
      } else {
        this.spinning = true;
        let category = this.form.category
          .slice(1, -1)
          .split(",")
          .map((str) => str.trim());
        changeCompany({
          proposalNo: this.$route.query.proposalNo,
          idNo: this.$route.query.idNo,
          customerCode: this.$route.query.customerCode,
          companyCode: this.form.companyCode,
          occupationList: category,
        }).then((res) => {
          if (res.code == "0") {
            showNotify({ type: "success", message: res.data });
            this.$router.go(-1);
          } else if (res.code == "1") {
            setTimeout(() => {
              this.$router.push({
                name: "login",
              });
            }, 1000);
          } else if (res.code == "2") {
            setTimeout(() => {
              this.$router.push({
                name: "login",
              });
            }, 1000);
          }
          this.spinning = false;
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
}
.bottomBox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  button {
    margin: 0 10px;
    height: 35px;
    background: #3e77db;
  }
}
//表头
/deep/.ant-table-thead > tr > th {
  background: rgb(221 235 255) !important;
  font-weight: bold !important;
}
</style>