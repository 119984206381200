<template>
  <div
    style="
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      margin-top: 10px;
    "
  >
    新增用工企业
  </div>
  <a-spin size="large" :spinning="spinning">
    <a-form
      layout="inline"
      :model="form"
      style="margin-bottom: 15px; padding: 10px"
      :rules="rules"
      @finish="onFinish"
    >
      <div class="container">
        <!-- 第一页 -->
        <a-row :gutter="[8, 24]" v-if="showModule == '0'">
          <a-col span="24">
            <a-form-item>
              <p style="height: 15px" slot="label">
                <span style="color: red; margin-right: 3px">*</span>公司全称
              </p>
              <a-input
                v-model:value="form.fullCompanyName"
                style="width: 100%"
                placeholder="请输入公司全称"
              />
            </a-form-item>
          </a-col>
          <a-col span="24">
            <a-form-item>
              <p style="height: 15px" slot="label">
                <span style="color: red; margin-right: 3px"></span>公司简称
              </p>
              <a-input
                v-model:value="form.companyName"
                style="width: 100%"
                placeholder="请输入公司简称"
              />
            </a-form-item>
          </a-col>
          <a-col span="24">
            <a-form-item>
              <p style="height: 15px" slot="label">
                <span style="color: red; margin-right: 3px"></span>上级公司名称
              </p>
              <a-select
                ref="select"
                allowClear
                v-model:value="form.parentCompanyCode"
                style="width: 100%"
                :options="parentCompany"
                placeholder="请选择上级公司名称"
              ></a-select>
            </a-form-item>
          </a-col>
          <a-col span="24">
            <a-form-item>
              <p style="height: 15px" slot="label">
                <span style="color: red; margin-right: 3px"></span>公司性质
              </p>
              <a-select
                ref="select"
                allowClear
                v-model:value="form.companyClass"
                style="width: 100%"
                :options="companyClass"
                placeholder="请选择公司性质"
              ></a-select>
            </a-form-item>
          </a-col>
          <a-col span="24">
            <a-form-item>
              <p style="height: 15px" slot="label">
                <span style="color: red; margin-right: 3px"></span>行业大类
              </p>
              <a-select
                ref="select"
                v-model:value="form.industry"
                allowClear
                style="width: 100%"
                :options="industry"
                @change="industryChange"
                placeholder="请选择行业大类"
              ></a-select>
            </a-form-item>
          </a-col>
          <a-col span="24">
            <a-form-item>
              <p style="height: 15px" slot="label">
                <span style="color: red; margin-right: 3px"></span>行业小类
              </p>
              <a-select
                ref="select"
                allowClear
                v-model:value="form.subIndustry"
                style="width: 100%"
                @click="subIndustryClick"
                :options="subIndustry"
                placeholder="请选择行业小类"
              ></a-select>
            </a-form-item>
          </a-col>
          <!-- 底部保存返回 -->
          <div class="bottomBox">
            <a-button type="primary" block @click="lastBtn((item = '1'))">
              <span>下一步</span>
            </a-button>
          </div>
        </a-row>
        <!-- 第二页 -->
        <a-row :gutter="[8, 24]" v-if="showModule == '1'">
          <a-col span="24">
            <a-form-item>
              <p style="height: 15px" slot="label">
                <span style="color: red; margin-right: 3px"></span>人员规模
              </p>
              <a-select
                ref="select"
                allowClear
                v-model:value="form.companySize"
                style="width: 100%"
                :options="companySize"
                placeholder="请选择人员规模"
              ></a-select>
            </a-form-item>
          </a-col>
          <a-col span="24">
            <a-form-item>
              <p style="height: 15px" slot="label">
                <span style="color: red; margin-right: 3px">*</span>地区
              </p>
              <a-select
                ref="select"
                placeholder="请选择地区"
                allowClear
                v-model:value="form.province"
                style="width: 100%"
                :options="province"
                @change="provinceChange"
              ></a-select>
            </a-form-item>
          </a-col>
          <a-col span="24">
            <a-form-item>
              <p style="height: 15px" slot="label">
                <span style="color: red; margin-right: 3px"></span>城市
              </p>
              <a-select
                ref="select"
                allowClear
                v-model:value="form.city"
                style="width: 100%"
                :options="city"
                @click="cityClick"
                placeholder="请选择城市"
              ></a-select>
            </a-form-item>
          </a-col>
          <a-col span="24">
            <a-form-item>
              <p style="height: 15px" slot="label">
                <span style="color: red; margin-right: 3px"></span>营业执照
              </p>
              <a-input
                v-model:value="form.idNo"
                style="width: 100%"
                placeholder="请输入营业执照"
              />
            </a-form-item>
          </a-col>
          <a-col span="24">
            <a-form-item>
              <p style="height: 15px" slot="label">
                <span style="color: red; margin-right: 3px">*</span>拟投保人数
              </p>
              <a-input-number
                v-model:value="form.reserveInsuranceNum"
                :parser="(value) => value.match(/^[0-9]+$/)"
                :min="0"
                style="width: 100%"
                placeholder="请输入拟投保人数"
              />
            </a-form-item>
          </a-col>
          <a-col span="24">
            <a-form-item>
              <p style="height: 15px" slot="label">
                <span style="color: red; margin-right: 3px">*</span>工作内容描述
              </p>
              <a-input
                v-model:value="form.remark"
                style="width: 100%"
                placeholder="请输入工作内容描述"
              />
            </a-form-item>
          </a-col>
          <div class="bottomBox">
            <a-button type="primary" block @click="lastBtn((item = '0'))">
              <span>上一步</span>
            </a-button>
            <a-button type="primary" block @click="lastBtn((item = '2'))">
              <span>下一步</span>
            </a-button>
          </div>
        </a-row>
        <!-- 第三页页 -->
        <a-row :gutter="[8, 24]" v-if="showModule == '2'">
          <a-col span="24" style="margin-bottom: 30px">
            <a-form-item>
              <p style="height: 15px" slot="label">
                <span style="color: red; margin-right: 3px">*</span>投保承诺
              </p>
              <div style="display: flex; align-items: center">
                <a-checkbox
                  v-model:checked="form.flag"
                  style="margin-right: 10px"
                ></a-checkbox>
                <a-textarea
                  readOnly
                  :rows="4"
                  style="width: 100%; z-index: 99; resize: none"
                  value="我承诺本次提交的员工工作内容描述及工作视频为我司与该用工企业合作的最高风险情形，如超过本次申报的风险类别，我司接受保司拒赔。"
                />
              </div>
            </a-form-item>
          </a-col>
          <div
            style="
              margin-top: 25px;
              width: 100%;
              padding: 10px;
              box-sizing: border-box;
            "
          >
            <a-form-item>
              <p style="height: 15px" slot="label">
                <span style="color: red; margin-right: 3px"></span>员工工作图片
                上传数量最多5张
              </p>
              <van-uploader
                style="padding: 10px"
                v-model="form.pictureList"
                accept="image/*"
                :max-count="5"
                multiple
              >
              </van-uploader>
            </a-form-item>
          </div>
          <div style="width: 100%; padding: 10px; box-sizing: border-box">
            <a-form-item>
              <p style="height: 15px" slot="label">
                <span style="color: red; margin-right: 3px"></span>员工工作视频
                上传数量最多3条
              </p>
              <van-uploader
                style="padding: 10px"
                v-model="form.fileList"
                :max-count="3"
                accept="video/*"
                multiple
              >
              </van-uploader>
            </a-form-item>
          </div>
          <div class="bottomBox">
            <a-button type="primary" block @click="lastBtn((item = '1'))">
              <span>上一步</span>
            </a-button>
            <a-button type="primary" block html-type="submit"
              >提交审核</a-button
            >
          </div>
        </a-row>
      </div>
    </a-form>
  </a-spin>
</template>
<script>
import { showToast } from "vant";
import { showNotify } from "vant";
import { areaQuery, getCustomerCompanyData, addCompany } from "@/api/api";
export default {
  data() {
    return {
      form: {
        fullCompanyName: "",
        companyName: "",
        idNo: "",
        parentCompanyCode: undefined,
        province: undefined,
        city: undefined,
        industry: undefined,
        subIndustry: undefined,
        companyClass: undefined,
        companySize: undefined,
        remark: "",
        reserveInsuranceNum: "",
        flag: false,
        pictureList: [],
        fileList: [],
      },
      showModule: "0",
      spinning: false,
      province: [],
      city: [],
      companyClass: [],
      companySize: [],
      industry: [],
      subIndustry: [],
      parentCompany: [],
    };
  },
  created() {
    getCustomerCompanyData().then((res) => {
      this.companyClass = res.customerCompanyData.companyClass;
      this.companySize = res.customerCompanyData.companySize;
      this.industry = res.customerCompanyData.industry;
      this.parentCompany = res.customerCompanyData.parentCompany;
    });
    areaQuery().then((res) => {
      this.province = res.data;
    });
  },
  methods: {
    lastBtn(item) {
      this.showModule = item;
    },
    // 上传图片
    afterUpload({ file }) {
      this.spinning = true;
      if (file.response) {
        if (file.response.code == "0") {
          showToast("上传图片成功");
          this.spinning = false;
        } else if (file.response.code == "500") {
          this.form.pictureList = [];
          showToast(file.response.msg);
          this.spinning = false;
        }
      }
    },
    // 上传视频
    afterUpload1({ file }) {
      this.spinning = true;
      if (file.response) {
        if (file.response.code == "0") {
          showToast("上传视频成功");
          this.spinning = false;
        } else if (file.response.code == "500") {
          this.form.fileList = [];
          showToast(file.response.msg);
          this.spinning = false;
        }
      }
    },
    //省市联动
    provinceChange() {
      this.province.forEach((v, i) => {
        if (v.value == this.form.province) {
          this.city = v.child;
        } else {
          this.form.city = undefined;
        }
      });
    },
    cityClick() {
      if (this.form.province == undefined) {
        showToast("请先选择地区");
        this.city = undefined;
      }
    },
    //行业联动
    industryChange() {
      this.industry.forEach((v, i) => {
        if (v.value == this.form.industry) {
          this.subIndustry = v.child;
        } else {
          this.form.subIndustry = undefined;
        }
      });
    },
    subIndustryClick() {
      if (this.form.industry == undefined) {
        showToast("请先选择行业大类");
        this.subIndustry = undefined;
      }
    },
    onFinish() {
      if (this.form.fullCompanyName == "") {
        showToast("请输入公司全称");
      } else if (this.form.province == undefined) {
        showToast("请选择地区");
      } else if (this.form.reserveInsuranceNum == "") {
        showToast("请输入拟投保人数");
      } else if (this.form.remark == "") {
        showToast("请输入工作内容描述");
      } else if (this.form.flag == false) {
        showToast("请勾选投保承诺");
      } else if (this.form.pictureList == "" && this.form.fileList == "") {
        showToast("请上传员工工作图片或视频");
      } else {
        this.spinning = true;
        addCompany(this.form).then((res) => {
          if (res.code == "0") {
            if (res.data == "提交成功") {
              showNotify({ type: "success", message: res.data });
              setTimeout(() => {
                this.spinning = false;
                this.$router.push({
                  name: "home",
                });
              }, 1000);
            } else if (res.data == "提交失败") {
              showNotify({ type: "error", message: res.data });
              this.spinning = false;
            }
          } else if (res.code == "1") {
            setTimeout(() => {
              this.$router.push({
                name: "login",
              });
            }, 1000);
          } else if (res.code == "2") {
            setTimeout(() => {
              this.$router.push({
                name: "login",
              });
            }, 1000);
          }
          setTimeout(() => {
            this.spinning = false;
          }, 15000);
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 15px;
  box-sizing: border-box;
}
.bottomBox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  button {
    margin: 0 10px;
    height: 35px;
    background: #3e77db;
  }
}
/deep/.ant-col-24 {
  margin-left: 5px;
  height: 60px;
}
/deep/.ant-form-item-label {
  height: 30px;
}
</style>